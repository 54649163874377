import {
  Box,
  Button,
  Container,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import DataGridComponent from "../projects/DataGridComponent";
import { makeStyles } from "@mui/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { deleteSmartList, getSmartList } from "../../redux/smartList";
import { useSelector } from "react-redux";
import EditSmartList from "./Edit";
import AddSmartList from "./Add";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ConfirmModal from "./ConfirmModal";

const useStyles = makeStyles((theme) => ({
  grid: {
    height: "calc(100vh - 300px)",
    backgroundColor: "white",
    maxHeight: "900px",
    minHeight: "500px",
  },
}));

export default function SmartList() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [rows, setRows] = React.useState([]);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const [openDeleteAlertModal, setOpenDeleteAlertModal] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(null);
  const [selectedType, setSelectedType] = React.useState("agent");
  const smartlists = useSelector((state) => state.smartlists.smartlists);
  const loading = useSelector((state) => state.smartlists.isLoading);

  const handleEditSmartList = (id) => {
    setSelectedID(id);
    setOpenEditModal(true);
  };

  const handleAddSmartList = (id) => {
    setOpenAddModal(true);
  };

  const handleDelete = () => {
    dispatch(deleteSmartList({ id: selectedID, type: selectedType }));
    handleCloseDeleteAlert();
  };

  const handleOpenDeleteAlert = (id) => {
    setSelectedID(id);
    setTimeout(() => {
      setOpenDeleteAlertModal(true);
    }, 500);
  };

  const handleCloseDeleteAlert = () => {
    setOpenDeleteAlertModal(false);
  };

  const [columns, setColumns] = React.useState([
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => params.value,
      flex: 1,
      sortable: true,
      filterable: false,
    },
    {
      field: "description",
      headerName: "Description",
      renderCell: (params) => params.value,
      flex: 1,
      sortable: true,
      filterable: false,
    },
    {
      field: "type",
      headerName: "Type",
      sortComparator: (v1, v2) => v1.type.localeCompare(v2.type),
      renderCell: (params) => params.value,
      flex: 1,
      sortable: true,
      filterable: false,
    },
    {
      field: "count",
      headerName: "Count",
      sortComparator: (v1, v2) => v1.count.localeCompare(v2.count),
      renderCell: (params) => params.value,
      maxWidth: 100,
      sortable: true,
      filterable: false,
    },
    {
      headerName: "Action",
      renderCell: (params) => {
        return (
          <Box display={"flex"} justifyContent={"center"}>
            <IconButton onClick={() => handleEditSmartList(params.id)}>
              <EditIcon sx={{ fontSize: 23 }} />
            </IconButton>

            <CopyToClipboard
              onCopy={() =>
                toast("Smart List Data copied to clipboard", {
                  hideProgressBar: true,
                  closeOnClick: false,
                })
              }
              text={`${JSON.stringify(params.row)}`}
            >
              <IconButton>
                <ContentCopyIcon sx={{ fontSize: 23 }} />
              </IconButton>
            </CopyToClipboard>

            <IconButton onClick={() => handleOpenDeleteAlert(params.id)}>
              <DeleteOutlineIcon sx={{ fontSize: 27 }} />
            </IconButton>
          </Box>
        );
      },
      sortable: false,
      minWidth: 150,
      filterable: false,
    },
  ]);

  function updateRowPosition(initialIndex, newIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        resolve(rowsClone);
      }, Math.random() * 500 + 100); // simulate network latency
    });
  }

  const handleRowOrderChange = async (params) => {
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    setRows(newRows);
  };

  React.useEffect(() => {
    dispatch(getSmartList(selectedType));
  }, [selectedType]);

  React.useEffect(() => {
    setRows([...smartlists]);
  }, [smartlists]);

  return (
    <Container maxWidth="lg" sx={{ mt: "50px", height: "100vh" }}>
      <EditSmartList
        open={openEditModal}
        setOpen={setOpenEditModal}
        selectedSmartList={smartlists.find((item) => item.id === selectedID)}
      />
      <AddSmartList
        open={openAddModal}
        setOpen={setOpenAddModal}
        setSelectedType={setSelectedType}
      />
      <ConfirmModal
        open={openDeleteAlertModal}
        handleYes={handleDelete}
        handleNo={handleCloseDeleteAlert}
      />

      <Box mb={"1rem"} display={"flex"} justifyContent={"end"} gap={"10px"}>
        <Box display={"flex"} gap={"10px"} alignItems={"center"}>
          <Select
            value={selectedType}
            name="type"
            fullWidth
            size="small"
            placeholder="Type"
            label="Type"
            variant={"outlined"}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <MenuItem value={"agent"}>Agent</MenuItem>
            <MenuItem value={"lead"}>Lead</MenuItem>
          </Select>
        </Box>
        <Button variant="contained" onClick={handleAddSmartList}>
          Add New
        </Button>
      </Box>

      <Box className={classes.grid}>
        <DataGridComponent
          loading={loading}
          rows={rows}
          columns={columns}
          rowReordering
          onRowOrderChange={handleRowOrderChange}
          hideFooter
        />
      </Box>
    </Container>
  );
}
