import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const deletePayment = createAsyncThunk(
  "Payments/deletePayment",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/users/v2/tabs/payment/${data?.schema?.payment_id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (resp.data && data?.handleSuccess) {
        data?.handleSuccess();
      }
      return {
        ...resp.data,
        payment_id: data?.schema.payment_id,
        amount: data?.schema?.amount,
      };
    } catch (error) {
      if (error.response) {
        if (data?.handleError) {
          data?.handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (data?.handleError) {
          data?.handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
