import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Paper,
  ListItemText,
  Chip,
  Avatar,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { getProcessedClosings } from "../../../redux/finance/getProcessedClosings";
import TableSkeleton from "../../../components/loader/tableLoader";

function ProcessedTable(props) {
  let { classes } = props;
  const dispatch = useDispatch();
  const {
    loading,
    allClosings: data,
    totalCount,
  } = useSelector((item) => item.finance.processedClosings);
  useEffect(() => {
    dispatch(
      getProcessedClosings({
        pageSize: 50,
        pageNumber: 1,
        reset: true,
      })
    );
  }, []);
  const columns = [
    {
      field: "address",
      headerName: "Address",
      renderCell: (params) => {
        return params.row.street;
      },
      sortComparator: (v1, v2) => v1.AssetName.localeCompare(v2.AssetName),
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return params.row.status === "closed(queued)" ? "Queued" : "Processed";
      },
      sortComparator: (v1, v2) =>
        v1.SerialNumber.localeCompare(v2.SerialNumber),
      width: 150,
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "agentName",
      headerName: "Agent Name",
      renderCell: (params) => {
        return params.row.agent_name;
      },
      sortComparator: (v1, v2) =>
        v1.SerialNumber.localeCompare(v2.SerialNumber),
      width: 150,
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      renderCell: (params) => {
        return `$${parseFloat(params.row.amount_to_disburse).toFixed(2)}`;
      },
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
      renderCell: (params) => {
        return params.row.aged;
      },
      width: 170,
      editable: false,
      sortable: false,
    },
    {
      field: "Actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            sx={{
              width: "70px",
              height: "30px",
              fontSize: "13px",
              backgroundColor: "black",
              "&:focus": {
                backgroundColor: "black",
              },
            }}
            variant="contained"
            component={Link}
            to={`/finance/closings/processed/${params.row.id}`}
          >
            View
          </Button>
        );
      },
      width: 130,
      editable: false,
      sortable: false,
    },
  ];
  return (
    <Box>
      {" "}
      <br />
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={data}
          columns={columns}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowsScrollEnd={(e) => {
            console.log(totalCount);
            if (totalCount > e.visibleRowsCount && !loading) {
              dispatch(
                getProcessedClosings({
                  pageNumber: Number(e.visibleRowsCount / 50) + 1,
                  pageSize: 50,
                })
              );
            }
          }}
          loading={loading}
          rowHeight={56}
          disableColumnResize
          disableColumnMenu
          disableChildrenSorting
        />
      </Paper>
    </Box>
  );
}
export default ProcessedTable;
