import React from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Paper, Stack, Box, Grid, Chip, ListItemText } from "@mui/material";
import TableSkeleton from "../../components/loader/tableLoader.jsx";
import { useSelector } from "react-redux";
import { CustomTooltip } from "../../components/shared/tooltip";
import Avatar from "../../components/uiElements/avatar/index";
function EquipmentTable(props) {
  let { classes, InventoryList, filter, setFilters } = props;
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const columns = [
    {
      field: "AssetName",
      headerName: "Name",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box className={classes.tableImageArea}>
              {" "}
              <img
                src={params.row.Icon}
                style={{ width: "25px" }}
                alt="agent Equipment Icon"
              />
            </Box>
            <Box> {params.row.AssetName}</Box>
          </Stack>
        );
      },
      width: 260,
      editable: false,
      sortable: true,
    },
    {
      field: "SerialNumber",
      headerName: "Serial Number",
      flex: 0.5,
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid
              container
              onClick={() => redirectDetails(params.row.id)}
              className={classes.status}
            >
              {params.row.SerialNumber}
            </Grid>
          </>
        );
      },
      sortComparator: (v1, v2) =>
        v1.SerialNumber.localeCompare(v2.SerialNumber),
      width: 150,
      editable: false,
      sortable: true,
    },
    {
      field: "TagID",
      headerName: "Tag ID",
      flex: 0.5,
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid container onClick={() => redirectDetails(params.row.id)}>
              {params.row.tagId}
            </Grid>
          </>
        );
      },
      width: 120,
      editable: false,
      sortable: false,
    },
    {
      field: "AssetStatus",
      headerName: "Status",
      renderCell: ({ row }) => {
        return (
          <>
            {row?.AssetStatus ? (
              <CustomTooltip title={row?.AssetStatus?.replace(/_/g, " ")}>
                <Chip
                  size="small"
                  label={
                    row?.AssetStatus === "available"
                      ? "In Office"
                      : "Checked Out"
                  }
                  style={{
                    background:
                      row?.AssetStatus === "available"
                        ? "rgba(69, 167, 79, 0.75)"
                        : "rgba(111, 185, 227, 0.76)",
                    color: "white",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                />
              </CustomTooltip>
            ) : (
              ""
            )}
          </>
        );
      },
      minWidth: 120,
      flex: 0.4,
      editable: true,
      sortable: true,
    },
    {
      field: "check-out",
      headerName: "Agent Assigned",
      renderCell: (params) => {
        if (params.row["check-out"] && params.row["check-out"].users_v2) {
          let userDetail = params.row["check-out"].users_v2;
          let profile_images = agentRosterList?.list.find(
            (item) => item.id === params.row["check-out"].users_v2?.id
          )?.profile_images;
          return (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Avatar
                sx={{ fontSize: "14.5px" }}
                src={
                  profile_images?.profile_img ||
                  profile_images?.profile_img_thumbnail ||
                  profile_images?.slack_profile_img
                }
                variant="rounded"
              >
                {userDetail?.first_name?.slice(0, 1) +
                  userDetail?.last_name?.slice(0, 1)}
              </Avatar>
              <ListItemText
                classes={{
                  primary: classes.listTitleInventory,
                  secondary: classes.listDesInventory,
                }}
                primary={userDetail?.first_name}
                secondary={userDetail?.last_name}
              />
            </Stack>
          );
        } else {
          return null;
        }
      },
      valueGetter: (params) => params.row["check-out"]?.users_v2?.first_name,
      minWidth: 150,
      editable: false,
      sortable: true,
      flex: 0.5,
    },
  ];
  const redirectDetails = (id) => {};
  return (
    <Paper className={classes.tableRoot}>
      <DataGridPro
        rows={
          InventoryList?.data?.rows && !InventoryList.isLoading
            ? InventoryList?.data?.rows
            : []
        }
        columns={columns}
        loading={InventoryList.isLoading}
        rowHeight={56}
        components={{
          LoadingOverlay: TableSkeleton,
        }}
        onRowsScrollEnd={(e) => {
          if (
            InventoryList.data?.count > e.visibleRowsCount &&
            !InventoryList.isLoading
          ) {
            setFilters({
              ...filter,
              pageNumber: Number(e.visibleRowsCount / 25) + 1,
              pageSize: 25,
            });
          }
        }}
      />
    </Paper>
  );
}
export default withStyles(styleSheet, { name: "EquipmentTableStyle" })(
  EquipmentTable
);
