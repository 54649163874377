export const styleSheet = () => ({
  pageRoot: {
    marginTop: "10px",
    padding: "20px",
  },
  InventoryHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  tableRoot: {
    height: "calc(100vh - 180px)",
    width: "100%",
    backgroundColor: "white",
    maxHeight: "900px",
    minHeight: "500px",
  },
  InventoryAddButton: {
    background: "#0B0909 !important",
    color: "white",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "9px  !important",
  },
  tableImageArea: {
    width: "90px",
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  addItemModalHeading: {
    fontSize: "22px !important",
    fontWeight: "400 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  AddInventoryButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  AddPostButton: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  addInventoryLabel: {
    fontWeight: "500 !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    fontSize: "14px !important",
    marginBottom: "10px !important",
  },
  InventoryIconBox: {
    padding: "10px",
    paddingLeft: "0px",
    backgroundColor: "white",
  },
  InventoryIconBoxIcon: {
    width: "32px",
  },
  dropdowns: {
    padding: "16px 15px !important",
    paddingRight: "15px !important",
  },
  labels: {
    fontWeight: "400",
    fontSize: "16px",
    marginBottom: "2px",
    marginTop: "0px",
  },
  btns: {
    background: "white !important",
    color: "black !important",
    border: "1px solid #212121 !important",
    boxShadow: "none !important",
  },
  tableImg: {
    width: "150px",
    height: "47px",
    objectFit: "cover",
    borderRadius: "10px",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
  },
  audience: {
    height: "37px",
    background: "black",
    color: "white !important",
    "& .MuiSvgIcon-root": {
      color: "white !important",
    },
  },
  "& .AddInventoryItemStyle-audience-87 .MuiSvgIcon-root": {
    color: "white !important",
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  checkName: {
    background: "gray",
    width: "35px",
    height: "35px",
    borderRadius: "100px",
    padding: "10px",
    marginRight: "5px",
  },
  checkNameP: {
    margin: "0 !important",
    lineHeight: "15px",
    fontSize: "18px",
    color: "white",
  },
  checkNameh5: {
    margin: "0",
    fontSize: "17px",
    fontWeight: "500",
  },
  TopSubNavBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopSubNavBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  "@media (max-width: 1270px)": {
    TopSubNavTabsUI: {
      maxWidth: "420px",
    },
  },
  "@media (max-width: 1050px)": {
    TopSubNavTabsUI: {
      maxWidth: "300px",
    },
  },
  TopSubNavTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
});
