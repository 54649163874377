import * as React from "react";
import Box from "@mui/material/Box";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { AgentRecruitmentCustomFilters } from "../filters/AgentRecruitmentCustomFilters";
import { CustomFilterableDataGrid } from "../../../../components/uiElements/customFilterabeDataGrid/CustomFilterableDataGrid";
import {
  getAgentRecruitingIdsAfterFilter,
  getAgentRecruitingList,
  setRecruitmentColumnsOrder,
  setRecruitmentColumnsVisibility,
  setRecruitmentFilter,
  setRecruitmentSort,
} from "../../../../redux/agents/getAgentList";
import { AgentRecruitmentCustomToolbar } from "../filters/AgentRecruitmentCustomToolbar";
import { SidebarAllFilter } from "../filters/SidebarAllFilter";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { getColumns } from "./constants";
import { useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
// Table Columns
const AgentRecruitsTable = ({
  classes,
  data,
  filters,
  sorting,
  totalAgentsCount,
  tagsList,
  tagsListLoading,
  stagesList,
  stagesListLoading,
  citiesListGAMLS,
  citiesListFMLS,
  citiesLoading,
  zipCodeLoading,
  zipCodesListFMLS,
  zipCodesListGAMLS,
  zipCodesListMiamire,
  loading,
  columnsOrder,
  columnsVisibility,
  zipCodesListStellar,
  citiesListStellar,
  citiesListMiamire,
  usersList,
  usersListLoading,
  officesListLoading,
  usersObj,
  searchText,
  officesList,
  setSearchText,
  zipCodesListCarolina,
  citiesListCarolina,
  searchValue,
}) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useGridApiRef();
  const [columns, setColumns] = React.useState(
    getColumns(
      columnsOrder,
      tagsList,
      stagesList,
      citiesListGAMLS,
      citiesListFMLS,
      zipCodesListFMLS,
      zipCodesListGAMLS,
      zipCodesListStellar,
      citiesListStellar,
      usersList,
      usersObj,
      officesList,
      citiesListMiamire,
      zipCodesListMiamire,
      zipCodesListCarolina,
      citiesListCarolina
    )
  );
  useEffect(() => {
    if (
      !zipCodeLoading &&
      !citiesLoading &&
      !stagesListLoading &&
      !tagsListLoading &&
      !officesListLoading
    ) {
      setColumns(
        getColumns(
          columnsOrder,
          tagsList,
          stagesList,
          citiesListGAMLS,
          citiesListFMLS,
          zipCodesListFMLS,
          zipCodesListGAMLS,
          zipCodesListStellar,
          citiesListStellar,
          usersList,
          usersObj,
          officesList,
          citiesListMiamire,
          zipCodesListMiamire,
          zipCodesListCarolina,
          citiesListCarolina
        )
      );
    }
  }, [
    columnsOrder,
    zipCodeLoading,
    citiesLoading,
    stagesListLoading,
    tagsListLoading,
    officesListLoading,
  ]);

  //----- save state of drag and drop of columns
  useEffect(() => {
    gridRef.current.subscribeEvent("columnHeaderDragEnd", (params) => {
      let newCols = gridRef.current.getAllColumns();
      newCols.shift();
      newCols = newCols.map((c) => c.field);
      dispatch(setRecruitmentColumnsOrder(newCols));
    });
  }, []);

  const setFilters = (newFilters) => {
    dispatch(setRecruitmentFilter(newFilters));
  };
  const setSorting = (sortFilter) => {
    dispatch(
      setRecruitmentSort({
        sortBy: sortFilter.field,
        sortOrder: sortFilter.sort,
      })
    );
  };
  const applyFilters = (newFilters) => {
    dispatch(
      getAgentRecruitingList({
        filters: JSON.stringify(newFilters ? newFilters : filters),
        ...sorting,
        pageSize: 25,
        pageNumber: 1,
        resetList: true,
      })
    );
    dispatch(
      getAgentRecruitingIdsAfterFilter({
        ...sorting,
        filters: JSON.stringify(newFilters ? newFilters : filters),
        pageSize: 25,
        pageNumber: 1,
        resetList: true,
      })
    );
  };
  // Detail view trigger for agent
  const onRowClick = async ({ id }) => {
    navigate(`/tools/recruiting/details/${id}`);
  };
  return (
    <>
      <Box
        sx={{
          margin: "10px 5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1" component="p">
          Showing {totalAgentsCount} People
        </Typography>
        {setSearchText ? (
          <OutlinedInput
            value={searchText === false ? "" : searchText}
            onChange={(e) => setSearchText(e.target.value.toLowerCase())}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
            size="small"
            placeholder="Search..."
          />
        ) : null}
      </Box>
      <Grid container spacing={1}>
        <Grid item lg={filters?.length === 0 ? 12 : 9} md={12}>
          <Box className={classes.tableRoot}>
            <CustomFilterableDataGrid
              columns={columns}
              rows={data}
              columnVisibilityModel={columnsVisibility}
              apiRef={gridRef}
              sortModel={
                sorting.sortBy
                  ? [{ field: sorting.sortBy, sort: sorting.sortOrder }]
                  : []
              }
              filters={filters}
              rowHeight={56}
              checkboxSelection
              tagsList={tagsList}
              officesList={officesList}
              loading={loading}
              filterMode="server"
              sortingMode="server"
              filterPanel={AgentRecruitmentCustomFilters}
              toolbarPanel={AgentRecruitmentCustomToolbar}
              onRowsScrollEnd={(e) => {
                if (totalAgentsCount > e.visibleRowsCount && !loading) {
                  dispatch(
                    getAgentRecruitingList({
                      ...sorting,
                      pageNumber: Number(e.visibleRowsCount / 25) + 1,
                      pageSize: 25,
                      filters: JSON.stringify(filters),
                    })
                  );
                  dispatch(
                    getAgentRecruitingIdsAfterFilter({
                      ...sorting,
                      filters: JSON.stringify(filters),
                      pageSize: 25,
                      pageNumber: 1,
                      resetList: true,
                    })
                  );
                }
              }}
              onSortModelChange={(e) => {
                setSorting(e[0] ? e[0] : {});
              }}
              onRowClick={(param) => onRowClick(param)}
              onPreferencePanelClose={(opened) => {
                if (opened.openedPanelValue === "filters") {
                  applyFilters();
                }
              }}
              onColumnVisibilityModelChange={(visibility) => {
                dispatch(setRecruitmentColumnsVisibility(visibility));
              }}
              onFilterModelChange={(e) => {
                setFilters(e.items);
              }}
            />
          </Box>
        </Grid>
        {filters?.length > 0 ? (
          <Grid item lg={3} md={12}>
            <SidebarAllFilter
              filters={filters}
              columns={columns}
              apiRef={gridRef}
              apiCallApplyFilter={applyFilters}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default withStyles(styleSheet, { name: "agentRecruitsTableStyle" })(
  AgentRecruitsTable
);
