import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const reactivateListing = createAsyncThunk(
  "listingDetail/reactivateListing",
  async (data, thunkAPI) => {
    try {
      console.log('Calling reactivate endpoint:', `${process.env.REACT_APP_BASE_URL}api/transactions/listings/${data.id}/reactivate`);
      
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/transactions/listings/${data.id}/reactivate`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      
      console.log('Reactivate response:', resp.data);
      
      return resp.data?.data;
    } catch (error) {
      console.error('Reactivate error:', error.response || error);
      if (error.response) {
        return thunkAPI.rejectWithValue(
          error.response.data.message || "Failed to reactivate listing"
        );
      } else {
        return thunkAPI.rejectWithValue(error.message || "Network error");
      }
    }
  }
);
