export const styleSheet = () => ({
  pageRoot: {
    marginTop: "15px !important",
    minHeight: "100vh",
  },
  topAreaEvents: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topAreaEventsHeading: {
    fontWeight: "600  !important",
    fontSize: "14px !important",
    fontFamily: "AvenirNext  !important",
  },
});
