export const styleSheet = () => ({
  root: {
    backgroundColor: "#fff",
    boxShadow: `0px 0px 0px 1px #E0E0E0`,
    borderRadius: "4px",
    height: "1120px",
    margin: "75px auto",
    padding: "110px 18px 18px 18px",
    position: "relative",
  },
  groupsPageRout: {
    minHeight: "100vh",
    padding: "20px 0px",
  },
  topPanel: {
    height: "463px",
    padding: "26px 36px",
    "& h6": {
      fontWeight: 500,
      marginBottom: "36px",
    },
    "& p": {
      marginBottom: "24px",
    },
    "&.hidden": {
      display: "none",
    },
  },

  //   select channel element
  channelSelect: {
    width: "232px",
    boxSizing: "border-box",
    cursor: "pointer",
    "& button": {
      "&.Mui-expanded": {
        "&::after": {
          transform: "scale(1, -1)",
        },
      },

      "&::after": {
        content: "'▾'",
        position: "absolute",
        right: 0,
        top: 0,
        width: "58px",
        backgroundColor: "rgba(0, 0, 0, 1)",
        borderRadius: "4px",
        textAlign: "right",
        padding: "8px 15px",
        fontSize: "16px",
      },
    },
    "& .MuiSelectUnstyled-root": {
      cursor: "pointer",
      height: "36px",
      backgroundColor: "#212121",
      borderRadius: "4px",
      color: "#fff",
      boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)`,
      textAlign: "left",
      border: "none",
      paddingLeft: "16px",
    },
    "& .MuiPopperUnstyled-root": {
      minWidth: "232px",
      boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)`,
      borderRadius: "4px",
      zIndex: 10,
      "& ul": {
        padding: 0,
        margin: 0,
        backgroundColor: "#fff",
      },
    },
  },

  //  button group

  customButtonGroup: {
    position: "absolute",
    right: "19px",
    bottom: "15px",
    "& button": {
      height: "40px",
      fontFamily: "AvenirNext",
      fontSize: "15px",
      width: "127px !important",
      "&.MuiButton-text": {
        color: "rgba(11, 9, 9, 1)",
      },
      "&.MuiButton-contained": {
        backgroundColor: "rgba(11, 9, 9, 1)",
      },
      "&:first-of-type": {
        marginRight: "10px",
      },
    },
  },
  // defaultLeadFloat

  defaultLeadFloat: {
    position: "absolute",
    top: "32px",
    right: "68px",
    display: "flex",
    alignItems: "flex-end",

    "& .MuiTypography-root": {
      marginRight: "29px",
      fontSize: "20px",
      fontFamily: "AvenirNext",
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 500,
      letterSpacing: "0.15px",
    },
  },

  //   grid

  routingGrid: {
    "& .MuiTypography-h6": {
      fontFamily: "AvenirNext",
      fontWeight: 500,
      marginLeft: "18px",
    },
    "& .MuiStepConnector-root": {
      width: "100%",
    },
    "& .MuiButtonBase-root": {
      color: "rgba(11, 9, 9, 1)",
      fontFamily: "AvenirNext",
      textAlign: "bottom",
      marginLeft: "18px",
      "& .MuiButton-startIcon": {
        paddingBottom: "4px",
      },
    },
    "& .MuiDataGrid-root": {
      border: "none",
      "&:first-of-type": {
        margin: "0 40px 0 35px",
      },
      "&:last-of-type": {
        margin: "0 35px 0 40px",
      },
    },
  },
  gridTitle: {
    display: "flex",
  },

  // modal

  modalInput: {
    display: "flex",
    marginTop: "22px",
  },
  // groups
  groupHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "6px",
  },
  groupAddButton: {
    background: "#0B0909 !important",
    color: "white",
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)  !important",
    borderRadius: "9px  !important",
    fontSize: "14px !important",
    fontFamily: "AvenirNext  !important",
    fontWeight: "500  !important",
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    lineHeight: "30px",
    maxHeight: "900px",
    minHeight: "500px",
  },
  groupHeading: {
    fontSize: "16px !important",
    fontFamily: "AvenirNext  !important",
    fontWeight: "600  !important",
    lineHeight: "40px !important",
  },
  commisionSelectionButTon: {
    backgroundColor: "#0B0909 !important",
    color: "white !important",
    fontWeight: "400 !important",
    marginRight: "10px !important",
    paddingLeft: "30px !important",
    paddingRight: "30px !important",
  },
  paymentModalHeading: {
    fontSize: "22px !important",
    fontWeight: "500 !important",
    fontFamily: "AvenirNext  !important",
    textTransform: "capitalize !important",
  },
  groupOptionSelectionLeft: {
    borderRight: "1px solid lightgrey",
    width: "50%",
  },
  groupOptionSelectionRight: {
    width: "50%",
  },
  // lead routing overviewe
  leadRoutingOverviewCard: {
    padding: "20px",
    backgroundColor: "white",
    marginTop: "50px",
    minHeight: "80vh",
  },
  leadRoutingOverviewCardTopArea: {
    display: "flex",
    justifyContent: "flex-end",
  },
  leadRoutingOverviewCardTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "20px",
    "& .MuiTypography-root": {
      marginRight: "20px !important",
      fontSize: "18px  !important",
      fontFamily: "AvenirNext  !important",
      color: "rgba(0, 0, 0, 0.87)  !important",
      fontWeight: "500  !important",
      letterSpacing: "0.15px  !important",
    },
  },
  leadsFlowsHeading: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px",
    "& .MuiTypography-root": {
      marginRight: "20px !important",
      fontSize: "18px  !important",
      fontFamily: "AvenirNext  !important",
      color: "rgba(0, 0, 0, 0.87)  !important",
      fontWeight: "500  !important",
      letterSpacing: "0.15px  !important",
    },
  },
  leadsFlowsHeadingButton: {
    fontSize: "13px  !important",
    fontFamily: "AvenirNext  !important",
    fontWeight: "400  !important",
  },
  leadsFlowsListBox: {
    padding: "12px 20px",
  },
  inputLabelStyle: {
    fontWeight: "400",
    color: "#000000 !important",
    fontSize: "14px !important",
    marginBottom: "3px",
    marginLeft: "1px",
    fontFamily: "AvenirNext  !important",
  },
  leadsFlowsListBoxItem: {
    padding: "12px",
    marginBottom: "15px",
    "& .MuiTypography-root": {
      fontSize: "17px  !important",
      fontFamily: "AvenirNext  !important",
      color: "rgba(0, 0, 0, 0.87)  !important",
      fontWeight: "500  !important",
      letterSpacing: "0.15px  !important",
    },
    "& .MuiTableCell-root": {
      fontFamily: "AvenirNext  !important",
      fontWeight: "500  !important",
      fontSize: "14px  !important",
    },
  },
});
