import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  Paper,
  Avatar,
  ListItemText,
} from "@mui/material";

import momentTZ from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddKnowledgeBasePost from "./modals/AddNewPost.js";
import { getAllPostData } from "../../../redux/knowledge/posts.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../components/loader/tableLoader.jsx";
import { getAllCategoryDataList } from "../../../redux/knowledge/categories.js";

function ManageLibraryPosts(props) {
  let { classes } = props;
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [search, setSearch] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    search: "",
    reset: true,
  });
  const { postData, totalCount, loading } = useSelector(
    (state) => state.knowledgeBase.posts
  );
  const { categoryData } = useSelector(
    (state) => state.knowledgeBase.categories
  );
  const dispatch = useDispatch();

  const columns = [
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      flex: 1,
      renderCell: (params) => {
        return <img className={classes.tableImg} src={params.row.thumbnail} />;
      },
      sortable: false,
    },
    { field: "title", headerName: "Title", flex: 1, sortable: false },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      sortable: false,

      renderCell: (params) => {
        return categoryData.post_categories?.find(
          (element) => element.id === Number(params.row.category_id)
        )?.title;
      },
    },
    {
      field: "createdAt",
      headerName: "Date Added ",
      renderCell: (params) => {
        return momentTZ
          .tz(params.row.createdAt, "America/New_York")
          .format("MM/DD/YY");
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "updatedAt",
      headerName: "Last Modified",
      flex: 1,
      renderCell: (params) => {
        return momentTZ
          .tz(params.row.createdAt, "America/New_York")
          .format("MM/DD/YY");
      },
      sortable: false,
    },
    {
      field: "views",
      headerName: "Views",
      flex: 1,
      sortable: false,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      renderCell: ({ row }) => {
        // console.log(params);
        return row?.createdBy ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              src={row?.createdBy?.profile_images?.profile_img_thumbnail}
              variant="rounded"
            >
              {row?.createdBy?.full_name?.slice(0, 1) +
                row?.createdBy?.full_name?.split(" ")[1]?.slice(0, 1)}
            </Avatar>
            <ListItemText
              primary={row?.createdBy?.full_name?.split(" ")[0]}
              secondary={row?.createdBy?.full_name?.split(" ")[1]}
            />
          </Stack>
        ) : null;
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",

      renderCell: (params) => {
        return (
          <>
            <Button
              container
              onClick={() => {
                setEditable(params.row);
                setOpen(true);
              }}
            >
              Edit
            </Button>
          </>
        );
      },
      flex: 1,
      sortable: false,
    },
  ];

  useEffect(() => {
    dispatch(getAllCategoryDataList({ pageNumber: 1, pageSize: 1000 }));
  }, []);

  useEffect(() => {
    dispatch(getAllPostData(filters));
  }, [filters.search]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => {
        setFilters({ ...filters, search });
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  return (
    <React.Fragment>
      <Box className={classes.InventoryHeader}>
        <Typography variant="h5" gutterBottom component="div">
          Manage Library
        </Typography>
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Button
              className={classes.InventoryAddButton}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => setOpen(true)}
            >
              Add Post
            </Button>
            <TextField
              type="search"
              size="small"
              variant="outlined"
              placeholder="Search Post"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Stack>{" "}
        </Box>
      </Box>
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={postData}
          columns={columns}
          loading={loading}
          checkboxSelection={false}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          disableColumnResize
          disableColumnMenu
          disableColumnReorder
          disableChildrenSorting
          onRowsScrollEnd={(e) => {
            if (totalCount > e.visibleRowsCount && !loading) {
              dispatch(
                getAllPostData({
                  pageNumber: Number(e.visibleRowsCount / 25) + 1,
                  pageSize: 25,
                })
              );
            }
          }}
        />
      </Paper>
      <AddKnowledgeBasePost
        setEditable={setEditable}
        editable={editable}
        open={open}
        setOpen={setOpen}
        categoryData={categoryData.post_categories}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "ManageLibraryPostsStyle" })(
  ManageLibraryPosts
);
