import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style";
import {
  Typography,
  Container,
  Button,
  ListItemText,
  Stack,
  AvatarGroup,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import TableSkeleton from "../../components/loader/tableLoader";
import { getAllPonds } from "../../redux/ponds";
import Avatar from "../../components/uiElements/avatar";
import { CustomTooltip } from "../../components/shared/tooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CreatePond from "./createPond";
import DeletePond from "./deletePond";

const Ponds = ({ classes }) => {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    sortBy: "",
    filterByType: "",
    search: "",
  });

  const [selectedPond, setSelectedPond] = useState({});
  const pondsList = useSelector((item) => item.Ponds.pondsList);

  const usersList = useSelector((item) => item.users.storedListOfUsers.list);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: ({ row }) => {
        return row.name ? row.name : "--";
      },
      width: 220,
      flex: 1,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2?.name),
      editable: false,
      sortable: true,
    },
    {
      field: "address",
      headerName: "Pond Lead",
      renderCell: ({ row }) => {
        let user = usersList.find((item) => item.id === row.userId);
        return user ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              sx={{ fontSize: "12px", width: "30px", height: "30px" }}
              src={user?.profile_images?.profile_img_thumbnail}
              variant="circular"
            >
              {user?.first_name?.slice(0, 1) + user?.last_name?.slice(0, 1)}
            </Avatar>
            <ListItemText primary={user?.first_name + " " + user?.last_name} />
          </Stack>
        ) : null;
      },
      width: 250,
      flex: 0.8,
      editable: false,
      sortable: false,
    },
    {
      field: "category",
      headerName: "Team Members",
      renderCell: ({ row }) => {
        return row.userIds?.length ? (
          <AvatarGroup
            max={6}
            sx={{
              "& .MuiAvatarGroup-avatar": {
                width: "25px",
                height: "25px",
                fontSize: "14px",
              },
            }}
            variant="circular"
          >
            {row.userIds.map((userId, index) => {
              let user = usersList.find((item) => item.id === userId);
              return user ? (
                <CustomTooltip
                  title={user?.full_name}
                  key={userId}
                  placement="top"
                >
                  <Avatar
                    sx={{
                      width: "25px",
                      height: "25px",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    variant="circular"
                    alt={user?.full_name}
                    title={user?.full_name}
                    src={user?.profile_images?.profile_img}
                  >
                    {user?.first_name?.slice(0, 1) +
                      user?.last_name?.slice(0, 1)}
                  </Avatar>
                </CustomTooltip>
              ) : null;
            })}
          </AvatarGroup>
        ) : (
          ""
        );
      },
      width: 250,
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: null,
      headerName: "Actions",
      renderCell: ({ row }) => {
        return (
          <>
            <IconButton
              onClick={() => {
                setSelectedPond(row);
                setDeleteOpen(true);
              }}
            >
              {" "}
              <DeleteIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setSelectedPond(row);
                setOpen(true);
              }}
            >
              {" "}
              <EditOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
            </IconButton>
          </>
        );
      },
      editable: false,
      sortable: false,
      flex: 0.5,
    },
  ];

  useEffect(() => {
    dispatch(getAllPonds(filters));
  }, []);

  return (
    <Container maxWidth="xl" className={classes.pageRoot}>
      <Box sx={{ margin: "10px 5px" }} className={classes.pondTableHeader}>
        <Typography
          classes={{ root: classes.pondTableHeading }}
          variant="subtitle1"
          component="p"
        >
          {pondsList?.metadata?.total} Ponds
        </Typography>
        <Button
          onClick={() => {
            setOpen(true);
            setSelectedPond({});
          }}
          className={classes.pondAddButton}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
        >
          ADD Pond
        </Button>
      </Box>
      <Box className={classes.tableRoot}>
        <DataGridPro
          columns={columns}
          rows={pondsList.data || []}
          loading={pondsList.isLoading ? true : false}
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowsScrollEnd={(e) => {
            if (
              pondsList.data?.metadata?.count > e.visibleRowsCount &&
              !pondsList.isLoading
            ) {
              dispatch(
                getAllPonds({
                  ...filters,
                  pageNumber: Number(e.visibleRowsCount / 25) + 1,
                  pageSize: 25,
                })
              );
            }
          }}
        />
      </Box>
      <CreatePond open={open} setOpen={setOpen} selectedPond={selectedPond} />
      <DeletePond
        open={deleteOpen}
        setOpen={setDeleteOpen}
        selectedPond={selectedPond}
      />
    </Container>
  );
};

export default withStyles(styleSheet, { name: "PondsStyle" })(Ponds);
