import React, { useState, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Typography,
  FormControlLabel,
  Switch,
  Paper,
  Stack,
  Chip,
  AvatarGroup,
} from "@mui/material";
import AddAlertModal from "../modals/addAlert";
import {
  handleGetAlerts,
  handleGetScheduleAlerts,
} from "../../../redux/eventsAndAlerts/alerts";
import { useDispatch, useSelector } from "react-redux";
import TableSkeleton from "../../../components/loader/tableLoader.jsx";
import { handleGetAlertTemplates } from "../../../redux/eventsAndAlerts/template";
import Avatar from "../../../components/uiElements/avatar";
import { CustomTooltip } from "../../../components/shared/tooltip";
import { icons } from "../modals/addAlert";
const moment = require("moment-timezone");
export const handleGetParseData = (data) => {
  let newData = JSON.parse(data);
  if (typeof newData === "string") {
    return JSON.parse(newData);
  } else {
    return newData;
  }
};
function AlertPage(props) {
  let { classes, setOpenAddAlert, openAddAlert, search } = props;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    view_past_alerts: false,
    search: "",
  });
  const [filtersSchedule, setFiltersSchedule] = useState({
    pageNumber: 1,
    pageSize: 25,
    view_past_alerts: false,
  });
  const [alertData, setAlertData] = useState({});
  const dispatch = useDispatch();
  const alertList = useSelector((item) => item.eventAlerts.alerts.alertList);
  const scheduleAlertList = useSelector(
    (item) => item.eventAlerts.alerts.scheduleAlertList
  );
  const usersList = useSelector((item) => item.users?.storedListOfUsers?.list);
  const columns = [
    {
      field: "title",
      headerName: "Alert Title",
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            {row.icon ? (
              <Box className={classes.tableImageArea}>
                <img
                  width="26px"
                  src={icons.find((item) => item.value === row.icon)?.icon}
                  alt=""
                />
              </Box>
            ) : (
              <Box className={classes.tableImageArea}></Box>
            )}
            <Box sx={{ ml: "10px" }}> {row.title}</Box>
          </Stack>
        );
      },
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "body",
      headerName: "Description",
      width: 220,
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: "startsAt",
      headerName: "Posted Date & Time",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row.startsAt
              ? moment(row.startsAt).tz("America/New_York").format("l")
              : ""}{" "}
            {row.startTime
              ? moment(row.startTime, "HH:mm:ss").format("hh:mm A")
              : ""}
          </Box>
        );
      },
      width: 170,
      editable: false,
      sortable: true,
    },
    {
      field: "expiresAt",
      headerName: "Expires",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row.expiresAt
              ? moment(row.expiresAt).tz("America/New_York").format("l")
              : ""}{" "}
            {row.expireTime
              ? moment(row.expireTime, "HH:mm:ss").format("hh:mm A")
              : ""}
          </Box>
        );
      },
      width: 170,
      editable: false,
      sortable: true,
    },
    {
      field: "deployedTo",
      headerName: "Deployed To",
      flex: 0.5,
      renderCell: ({ row }) => {
        return typeof row.who_to_alert === "string" &&
          JSON.parse(row.who_to_alert)?.length ? (
          <AvatarGroup
            max={8}
            sx={{
              "& .MuiAvatarGroup-avatar": {
                width: "25px",
                height: "25px",
                fontSize: "14px",
              },
            }}
            variant="rounded"
          >
            {handleGetParseData(row.who_to_alert).map((item, index) => {
              let user = usersList.find(
                (item1) => item1.full_name === item || item1.name === item
              );
              if (user) {
                return (
                  <CustomTooltip title={user.full_name}>
                    <Avatar
                      title={user.full_name}
                      src={
                        user?.profile_images?.profile_img ||
                        user?.profile_images?.slack_profile_img
                      }
                      sx={{
                        width: "25px",
                        height: "25px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      variant="rounded"
                    >
                      {`${user.first_name?.slice(0, 1)}${user.last_name?.slice(
                        0,
                        1
                      )}`}
                    </Avatar>
                  </CustomTooltip>
                );
              } else {
                return (
                  <CustomTooltip title={item.replace(/_/g, " ")}>
                    <Avatar
                      title={item.replace(/_/g, " ")}
                      sx={{
                        width: "25px",
                        height: "25px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      variant="rounded"
                    >
                      {`${item
                        .replace(/_/g, " ")
                        .split(" ")[0]
                        .slice(0, 1)
                        ?.toUpperCase()}${item
                        .replace(/_/g, " ")
                        .split(" ")[1]
                        .slice(0, 1)
                        ?.toUpperCase()}`}
                    </Avatar>
                  </CustomTooltip>
                );
              }
            })}
          </AvatarGroup>
        ) : (
          ""
        );
      },
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "seen_count",
      headerName: "Seen By",
      width: 140,
      editable: false,
      sortable: true,
    },
  ];
  useEffect(() => {
    dispatch(handleGetAlerts(filters));
  }, [filters]);
  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(
        () => setFilters({ ...filters, search }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search]);
  useEffect(() => {
    dispatch(handleGetAlertTemplates({ pageNumber: 1, pageSize: 1000 }));
  }, []);
  useEffect(() => {
    dispatch(handleGetScheduleAlerts(filtersSchedule));
  }, [filtersSchedule]);
  const handleUpdateCheck = (flag) => {
    setFilters({
      ...filters,
      view_past_alerts: flag,
    });
    setFiltersSchedule({
      ...filtersSchedule,
      view_past_alerts: flag,
    });
  };
  const handleOpenDetail = (data) => {
    setAlertData(data);
    setOpenAddAlert("update");
  };
  return (
    <Box className={classes.pageRoot}>
      <Box className={classes.topAreaEvents}>
        <Typography className={classes.topAreaEventsHeading}>
          {alertList.data?.count || ""} Current Alerts
        </Typography>
        <FormControlLabel
          sx={{
            textTransform: "uppercase",
            "& 	.MuiFormControlLabel-label": {
              fontSize: "14px !important",
              color: "black",
            },
          }}
          control={
            <Switch
              onChange={(e) => handleUpdateCheck(e.target.checked)}
              checked={filters.view_past_alerts}
            />
          }
          label="See Expired Alerts"
        />
      </Box>
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={alertList.data.data ? alertList.data.data : []}
          columns={columns}
          loading={alertList.isLoading}
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowClick={(data) => handleOpenDetail(data.row)}
          onRowsScrollEnd={(e) => {
            if (
              alertList.data?.count > e.visibleRowsCount &&
              !alertList.isLoading
            ) {
              setFilters({
                ...filters,
                pageNumber: Number(e.visibleRowsCount / 25) + 1,
                pageSize: 25,
              });
            }
          }}
        />
      </Paper>
      <Box className={classes.topAreaEvents} sx={{ mt: "40px", mb: "10px" }}>
        <Typography className={classes.topAreaEventsHeading}>
          {" "}
          {scheduleAlertList.data?.count || ""} Scheduled Alerts
        </Typography>
      </Box>
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={scheduleAlertList.data.data ? scheduleAlertList.data.data : []}
          columns={columns}
          loading={scheduleAlertList.isLoading}
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowClick={(data) => handleOpenDetail(data.row)}
          onRowsScrollEnd={(e) => {
            if (
              scheduleAlertList.data?.count > e.visibleRowsCount &&
              !scheduleAlertList.isLoading
            ) {
              setFiltersSchedule({
                ...filtersSchedule,
                pageNumber: Number(e.visibleRowsCount / 25) + 1,
                pageSize: 25,
              });
            }
          }}
        />
      </Paper>
      <AddAlertModal
        open={openAddAlert}
        alertData={openAddAlert === "update" ? alertData : {}}
        setOpen={setOpenAddAlert}
        {...props}
      />
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "AlertPageStyle",
})(AlertPage);
