import React, { useState, useEffect } from "react";
import { Button, Container, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCommissionPlans } from "../../../redux/finance/commissionPlans";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { withStyles } from "@mui/styles";
import { styleSheet } from "../style.js";
import { CreateAndUpdateCommissionModal } from "../common/CreateAndUpdateCommissionModal";
import TableSkeleton from "../../../components/loader/tableLoader";

function CommissionPlans({ classes, commissionModal, setCommissionModal }) {
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([
    { field: "id", headerName: "ID", flex: 0.2 },
    { field: "name", headerName: "Plan Name", flex: 2 },
    { field: "agentPer", headerName: "Gross Agent Split", flex: 1 },
    { field: "companyPer", headerName: "Company Split", flex: 1 },
    {
      field: "renderCell",
      flex: 1,
      headerName: "Action",
      disableClickEventBubbling: true,

      renderCell: (params) => {
        return (
          <Button
            sx={{
              width: "70px",
              height: "30px",
              fontSize: "13px",
              backgroundColor: "black",
            }}
            variant="contained"
            onClick={(e) => {
              setId(params.row.id);
              setCommissionModal(true);
            }}
          >
            View
          </Button>
        );
      },
    },
  ]);
  const { data, isLoading, totalCount, search } = useSelector(
    (item) => item.finance.commissionPlans
  );

  useEffect(() => {
    dispatch(getCommissionPlans({ pageNumber: 1, pageSize: 25, reset: true }));
  }, []);

  return (
    <Container className={classes.StatsArea} maxWidth="lg">
      <Paper className={classes.commTableRoot}>
        <DataGridPro
          rows={data}
          columns={columns}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          loading={isLoading}
          rowHeight={56}
          onRowsScrollEnd={(e) => {
            if (totalCount > e.visibleRowsCount && !isLoading) {
              dispatch(
                getCommissionPlans({
                  pageNumber: Number(e.visibleRowsCount / 25) + 1,
                  pageSize: 25,
                  search: search,
                })
              );
            }
          }}
          disableColumnResize
          disableColumnMenu
          disableChildrenSorting
        />
      </Paper>
      {commissionModal ? (
        <CreateAndUpdateCommissionModal
          state={commissionModal}
          initialValue={data.find((d) => d.id == id)}
          id={id}
          classes={classes}
          closeModal={() => {
            setId(null);
            setCommissionModal(false);
          }}
        />
      ) : null}
    </Container>
  );
}
export default withStyles(styleSheet, { name: "CommissionPlansStyle" })(
  CommissionPlans
);
