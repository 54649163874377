import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  Paper,
} from "@mui/material";
import momentTZ from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getAllCategoryDataList } from "../../../redux/knowledge/categories.js";
import AddNewCategory from "./modals/AddNewCategory.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../components/loader/tableLoader";

function ManageLibraryCategories(props) {
  let { classes } = props;
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [search, setSearch] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    search: "",
  });
  const { categoryData, loading } = useSelector(
    (state) => state.knowledgeBase.categories
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategoryDataList(filters));
  }, [filters]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => {
        setFilters({ ...filters, search });
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const CategoryColumns = [
    { field: "title", headerName: "Title", flex: 1 },

    {
      field: "createdAt",
      headerName: "Date Added ",
      flex: 0.4,
      renderCell: (params) => {
        return momentTZ
          .tz(params.row.createdAt, "America/New_York")
          .format("MM/DD/YY");
      },
    },

    //
    {
      field: "views",
      headerName: "Total Posts",
      flex: 0.4,
      renderCell: (params) => {
        return params.row?.posts?.length;
      },
    },

    {
      field: "Actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <Button
              container
              onClick={() => {
                setEditable(params.row);
                setOpen(true);
              }}
            >
              Edit
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <Box className={classes.InventoryHeader}>
        <Typography variant="h5" gutterBottom component="div">
          Manage Library
        </Typography>
        <Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Button
              className={classes.InventoryAddButton}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setEditable(false);
                setOpen(true);
              }}
            >
              Add Category
            </Button>
            <TextField
              type="search"
              size="small"
              variant="outlined"
              placeholder="Search Category"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Stack>{" "}
        </Box>
      </Box>
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={
            categoryData.post_categories ? categoryData.post_categories : []
          }
          columns={CategoryColumns}
          loading={loading}
          checkboxSelection={false}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowsScrollEnd={(e) => {
            if (categoryData.count > e.visibleRowsCount && !loading) {
              dispatch(
                getAllCategoryDataList({
                  pageNumber: Number(e.visibleRowsCount / 25) + 1,
                  pageSize: 25,
                })
              );
            }
          }}
        />
      </Paper>
      <AddNewCategory
        setEditable={setEditable}
        editable={editable}
        open={open}
        setOpen={setOpen}
        categoryData={categoryData.post_categories}
      />
    </Box>
  );
}

export default withStyles(styleSheet, { name: "ManageLibraryCategoriesStyle" })(
  ManageLibraryCategories
);
