import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const updatePaymentStatus = createAsyncThunk(
  "Payments/updatePaymentStatus",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/users/v2/tabs/payment/status`,
        {
          ...data.schema,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data?.handleSuccess) {
        data?.handleSuccess();
      }
      return {
        ...resp.data,
        paymentIds: data?.schema?.paymentIds,
        status: data.schema.status,
      };
    } catch (error) {
      if (error.response) {
        if (data.handleError) {
          data.handleError(
            error.response.data.errorMessage || error.response.data.message
          );
        }
        return thunkAPI.rejectWithValue(
          error.response.data.errorMessage || error.response.data.message
        );
      } else {
        if (data.handleError) {
          data.handleError(error.message);
        }
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);
