import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Paper,
  ListItemText,
  Chip,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { CustomTooltip } from "../../../../components/shared/tooltip";
import Avatar from "../../../../components/uiElements/avatar/index";
import { getAllListings } from "../../../../redux/listings/listings";
import TableSkeleton from "../../../../components/loader/tableLoader";
import DataGridComponent from "../../../projects/DataGridComponent";
import { ListingColors } from "../../../../utils/listingStatusColor";

function ListingsTable(props) {
  const { classes, filters } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [pageNo, setPageNo] = useState(2);
  const ListingsAll = useSelector(
    (state) => state.listings.ListingsData.ListingsAll
  );
  const redirectDetails = (id) => {
    navigate(`/listings/listing-detail/${id}/detail-overview`);
  };

  React.useEffect(() => {
    setPageNo(2);
  }, [filters]);

  const columns = [
    {
      field: "Image",
      headerName: "Image",
      renderCell: (params) => {
        return (
          <Avatar
            variant="rounded"
            src={
              params?.row?.property_img ||
              process.env.REACT_APP_NO_PROPERTY_IMAGE
            }
            sx={{
              width: "99px",
              height: "75px",
              padding: "3px 0px",
              objectFit: "contain",
              borderRadius: "5px",
              "& img": { borderRadius: "5px" },
            }}
          />
        );
      },
      width: 130,
      editable: false,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Address",
      renderCell: (params) => {
        return (
          <ListItemText
            primary={params?.row?.street}
            secondary={`${params?.row?.city}, ${params?.row?.state}  ${params?.row?.zipcode}`}
          />
        );
      },
      width: 250,
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      renderCell: (params) => {
        // console.log(params);
        return (
          <Chip
            sx={{
              backgroundColor: !params.row.is_rental ? "#0288D1" : "#9575CD",
              minWidth: "100px",
              textTransform: "capitalize",
            }}
            label={params.row.is_rental ? "Rental" : "Sale"}
            color="success"
            size="small"
          />
        );
      },
      width: 150,
      editable: false,
      sortable: false,
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      renderCell: (params) => {
        return (
          <CustomTooltip title={params.row.status}>
            <Chip
              size="small"
              sx={{
                backgroundColor: ListingColors[params.row.status] || "#000000",
                minWidth: "100px",
                textTransform: "capitalize",
              }}
              label={params.row.status}
              color="success"
            />
          </CustomTooltip>
        );
      },
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "Agent",
      headerName: "Agent",
      renderCell: (params) => {
        // console.log(params);
        return params.row?.agent ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              src={params.row?.agent?.profile_images?.profile_img_thumbnail}
              variant="rounded"
            >
              {params?.row?.agent?.first_name?.slice(0, 1) +
                params?.row?.agent?.last_name?.slice(0, 1)}
            </Avatar>
            <ListItemText
              primary={params?.row?.agent?.first_name}
              secondary={params?.row?.agent?.last_name}
            />
          </Stack>
        ) : null;
      },
      width: 200,
      editable: false,
      sortable: true,
    },
    {
      field: "Category",
      headerName: "Documents",
      renderCell: (params) => {
        // console.log(params);
        return params.row?.transaction_documents ? (
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              sx={{ color: "#000" }}
              value={
                (Number(
                  params.row?.transaction_documents?.filter(
                    (item) => item.status === "approved"
                  )?.length || 0
                ) /
                  Number(
                    params.row?.transaction_documents?.filter(
                      (item) => item.status !== "exempt"
                    )?.length || 0
                  )) *
                100
              }
              {...props}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {`${Math.round(
                  (Number(
                    params.row?.transaction_documents?.filter(
                      (item) => item.status === "approved"
                    )?.length || 0
                  ) /
                    Number(
                      params.row?.transaction_documents?.filter(
                        (item) => item.status !== "exempt"
                      )?.length || 0
                    )) *
                    100
                )}%`}
              </Typography>
            </Box>
          </Box>
        ) : null;
      },
      width: 100,
      editable: false,
      sortable: false,
    },
    {
      field: "price",
      headerName: "Price",
      renderCell: (params) => {
        // console.log(params);
        return <Box>${new Intl.NumberFormat().format(params.row.price)}</Box>;
      },
      width: 150,
      editable: false,
      sortable: true,
    },
  ];
  return (
    <Box>
      {" "}
      <br />
      <Paper className={classes.tableRoot}>
        <DataGridComponent
          rows={ListingsAll.data ? ListingsAll.data : []}
          columns={columns}
          loading={ListingsAll.isLoading}
          rowHeight={80}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowClick={(row) => {
            redirectDetails(row.id);
          }}
          onRowsScrollEnd={(e) => {
            if (
              ListingsAll.count > e.visibleRowsCount &&
              !ListingsAll.isLoading
            ) {
              dispatch(
                getAllListings({
                  ...filters,
                  pageNumber: pageNo,
                  pageSize: 25,
                })
              );
              pageNo = pageNo + 1;
              setPageNo(pageNo);
            }
          }}
        />
      </Paper>
    </Box>
  );
}
export default ListingsTable;
