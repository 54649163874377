import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Paper, Stack, Box, Grid } from "@mui/material";
import TableSkeleton from "../../components/loader/tableLoader.jsx";
import SignageDetailModal from "../agentRoster/agentDetails/modals/signageDetailModal.js";
function SignageTable(props) {
  let { classes, InventoryList, filter, setFilters } = props;
  const [open, setOpen] = useState(false);
  const [signageDetail, setSignageDetail] = useState({});
  const columns = [
    {
      field: "AssetName",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box className={classes.tableImageArea}>
              {" "}
              <img
                src={params.row.Icon}
                style={{ width: "25px" }}
                alt="agent Equipment Icon"
              />
            </Box>
            <Box> {params.row.AssetName}</Box>
          </Stack>
        );
      },
      minWidth: 260,
      flex: 0.5,
      editable: false,
      sortable: true,
    },
    {
      field: "signageCount",
      headerName: "Total Inventory",
      renderCell: ({ row }) => {
        return (
          <Grid container className={classes.mAlign}>
            {Number(row?.SignageCount || "0") +
              Number(
                row["check-out"]
                  ? row["check-out"]?.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.signageCount || 0,
                      0
                    ) || 0
                  : "0"
              )}
          </Grid>
        );
      },
      minWidth: 150,
      editable: false,
      sortable: false,
      flex: 0.5,
    },
    {
      field: "CheckedOut",
      headerName: "Checked Out",
      renderCell: ({ row }) => {
        return (
          <Grid className={classes.mAlign}>
            {Number(
              row["check-out"]
                ? row["check-out"]?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.signageCount || 0,
                    0
                  ) || 0
                : "0"
            )}
          </Grid>
        );
      },
      minWidth: 150,
      editable: false,
      sortable: false,
      flex: 0.5,
    },
    {
      field: "InOffice",
      headerName: "In Office",
      renderCell: ({ row }) => {
        return (
          <Grid className={classes.mAlign}>
            {Number(row?.SignageCount || "0")}
          </Grid>
        );
      },
      minWidth: 150,
      editable: false,
      sortable: false,
      flex: 0.5,
    },
  ];
  const handleOpenDetail = (data) => {
    setSignageDetail(data);
    setOpen(true);
  };
  return (
    <Paper className={classes.tableRoot}>
      <DataGridPro
        rows={
          InventoryList?.data?.rows && !InventoryList.isLoading
            ? InventoryList?.data?.rows
            : []
        }
        columns={columns}
        loading={InventoryList.isLoading}
        rowHeight={56}
        components={{
          LoadingOverlay: TableSkeleton,
        }}
        onRowClick={(data) => handleOpenDetail(data.row)}
        onRowsScrollEnd={(e) => {
          if (
            InventoryList.data?.count > e.visibleRowsCount &&
            !InventoryList.isLoading
          ) {
            setFilters({
              ...filter,
              pageNumber: Number(e.visibleRowsCount / 25) + 1,
              pageSize: 25,
            });
          }
        }}
      />
      <SignageDetailModal
        open={open}
        setOpen={setOpen}
        signageDetail={signageDetail}
      />
    </Paper>
  );
}
export default withStyles(styleSheet, { name: "SignageTableStyle" })(
  SignageTable
);
