import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  Grid,
  Container,
  Box,
  LinearProgress,
  Stack,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDocList } from "../../../redux/docs/getDocList";
import HeaderDocs from "./headerDocs";
import { Folder, Done } from "@mui/icons-material";
import Avatar from "../../../components/uiElements/avatar/index";
import moment from "moment";
import AddFolderModal from "./addFolderModal";
import { getAgentList } from "../../../redux/agents/getAllAgent";
import ResponseAlert from "../../../components/responseAlert";
import { deleteFolder } from "../../../redux/docs/addfolderapi";
import { useNavigate } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../components/loader/tableLoader";

const DocsListing = ({ classes }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const agentData = useSelector((item) => item.allAgent);

  const data = useSelector((item) => item.docList);
  const [docaArray, setDocsArr] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [doclistForSearch, setDoclistForSearch] = useState([]);

  const [openerror, setOpenerror] = useState(false);
  const [alertType, setAlertType] = useState();
  const [alertMessage, setAlertMesage] = useState();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderCell: (params) => {
        return (
          <>
            <Grid
              container
              onClick={() => redirectDetails(params.row.id)}
              className={classes.mAlign}
            >
              <Folder sx={{ color: "rgba(0, 0, 0, 0.54)", mr: "15px" }} />
              {params.row.name}
            </Grid>
          </>
        );
      },
      width: 400,
      editable: false,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",

      renderCell: (params) => {
        return (
          <>
            <Grid
              container
              onClick={() => redirectDetails(params.row.id)}
              className={classes.status}
            >
              <Done
                sx={{
                  color: "rgba(0, 0, 0, 0.54)",
                  fontSize: "19px",
                  mr: "6px",
                }}
              />
              {params.row.status}
            </Grid>
          </>
        );
      },
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "first_name",
      headerName: "Agent Name",
      renderCell: (params) => {
        return params.row?.agent ? (
          <Stack
            onClick={() => redirectDetails(params.row.id)}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Avatar
              src={params.row?.agent?.profile_images?.profile_img}
              sx={{
                width: 30,
                height: 28,
                mr: "10px",
                borderRadius: "6px",
                textTransform: "uppercase",
                fontWeight: "400",
              }}
              variant="square"
            >
              {params?.row?.agent?.first_name?.slice(0, 1)}
            </Avatar>
            <ListItemText
              primary={params?.row?.agent?.first_name}
              secondary={params?.row?.agent?.last_name}
            />
          </Stack>
        ) : (
          ""
        );
      },
      sortComparator: (v1, v2) => v1.first_name.localeCompare(v2.first_name),
      width: 250,
      editable: false,
      sortable: true,
    },
    {
      // type:"dateTime",
      field: "dateModified",
      headerName: "Date Modified",
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            <Grid container onClick={() => redirectDetails(params.row.id)}>
              {params.value.dateDoc}
            </Grid>
          </>
        );
      },
      width: 300,
      editable: false,
      sortable: true,
    },
  ];

  const handleOpen = () => {
    setOpen(true);
    dispatch(getAgentList());
  };

  const handleClose = () => setOpen(false);

  const handleSearchInputChange = (e) => {
    if (e.target.value) {
      const filterData =
        doclistForSearch?.length &&
        doclistForSearch.filter(
          (item) =>
            item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            item.listing_mls_id
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        );

      if (filterData?.length) {
        arrangeDocsData(filterData);
      } else {
        arrangeDocsData(filterData);
      }
    } else {
      arrangeDocsData(doclistForSearch);
    }
  };

  useEffect(() => {
    if (data.docList.length > 0) {
      setDoclistForSearch(data.docList);
      arrangeDocsData(data.docList);
    }
  }, [data.docList, data.loading]);

  useEffect(() => {
    getAllDocList();
  }, []);

  const getAllDocList = () => {
    dispatch(getDocList());
  };

  const redirectDetails = (id) => {
    navigate(`/docs/${id}`);
  };

  const arrangeDocsData = (data) => {
    if (data.length > 0) {
      let temp = [];
      data.forEach((element) => {
        let details = {
          id: element.id,
          status: { status: element.status },
          dateModified: {
            dateDoc: moment(element.updatedAt).format(
              "MMMM Do YYYY, h:mm:ss a"
            ),
          },
          ...element,
        };

        temp.push(details);
      });

      if (temp.length > 0) {
        setDocsArr(temp);
      }
    }
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params);
  };

  const archiveFunction = () => {
    if (selectedCheckboxIds?.length > 0) {
      Promise.all(
        selectedCheckboxIds?.length &&
          selectedCheckboxIds.map(async (forlderId) => {
            await dispatch(deleteFolder(forlderId));
          })
      ).then(() => {
        setDocsArr([]);
        getAllDocList();
        setOpenerror(true);
        setAlertType("success");
        setAlertMesage("You have successfully Archive the folder");
      });
    } else {
      setOpenerror(true);
      setAlertType("error");
      setAlertMesage("Please select folders or folder");
    }
  };
  return (
    <div className={classes.pageRoot}>
      <HeaderDocs
        addFolderModal={handleOpen}
        selectedCheckboxIds={selectedCheckboxIds}
        archiveFunction={archiveFunction}
        handleSearchInputChange={handleSearchInputChange}
      />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGridPro
                loading={data.loading}
                components={{
                  LoadingOverlay: TableSkeleton,
                }}
                rows={docaArray ? docaArray : []}
                columns={columns}
                pageSize={pageSize}
                checkboxSelection={true}
                rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                onSelectionModelChange={(itm) => setSelectedCheckboxIds(itm)}
                handlePageSizeChange={handlePageSizeChange}
              />
              <AddFolderModal
                open={open}
                handleClose={handleClose}
                modalWidht="auto"
                agentData={agentData.loading ? [] : agentData.allAgent}
              />

              <ResponseAlert
                setOpen={setOpenerror}
                open={openerror}
                alertType={alertType}
                alertMessage={alertMessage}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(styleSheet, { name: "DocsStyle" })(DocsListing);
