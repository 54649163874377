import React, { useState, useEffect } from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Paper,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddAlertTemplate from "../modals/addTemplate";
import { handleGetAlertTemplates } from "../../../redux/eventsAndAlerts/template";
import { useDispatch, useSelector } from "react-redux";
import TableSkeleton from "../../../components/loader/tableLoader.jsx";
function AlertTemplates(props) {
  let { classes, openAddTemplate, setOpenAddTemplate, search } = props;
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 25,
    search: "",
  });
  const [templateData, setTemplateData] = useState({});
  const dispatch = useDispatch();
  const templateList = useSelector(
    (item) => item.eventAlerts.templates.templateList
  );
  const usersList = useSelector((item) => item.users?.storedListOfUsers?.list);
  const columns = [
    {
      field: "title",
      headerName: "Template",
      renderCell: (params) => {
        return (
          <Box>
            <ListItemText
              primary={
                <Box sx={{ color: "#0288D1", fontSize: "14px" }}>
                  {params?.row?.title}
                </Box>
              }
              secondary={
                <Box
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    width: "660px",
                  }} /*  dangerouslySetInnerHTML={{ __html: params?.row?.body}} */
                >
                  {params?.row?.body}
                </Box>
              }
            />
          </Box>
        );
      },
      width: 660,
      flex: 1,
      editable: false,
      sortable: true,
    },
    {
      field: "full_name",
      headerName: "Owner",
      renderCell: (params) => {
        // console.log(params);
        return (
          <>
            {params?.row?.owner?.full_name ? (
              <Grid sx={{ color: "#0288D1", fontSize: "14px" }} container>
                {params?.row?.owner?.full_name}
              </Grid>
            ) : params?.row?.user_id ? (
              <Grid sx={{ color: "#0288D1", fontSize: "14px" }} container>
                {
                  usersList.find((item) => item.id === params?.row?.user_id)
                    ?.full_name
                }
              </Grid>
            ) : null}
          </>
        );
      },
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <IconButton
                onClick={() =>
                  handleOpenDetail({ ...params?.row, updateFlag: true })
                }
              >
                {" "}
                <EditIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              </IconButton>
            </Box>
          </>
        );
      },
      type: "actions",
      width: 150,
      editable: false,
      sortable: false,
    },
  ];
  useEffect(() => {
    dispatch(handleGetAlertTemplates(filters));
  }, [filters]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(
        () => setFilters({ ...filters, search }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  const handleOpenDetail = (data) => {
    setTemplateData(data);
    setOpenAddTemplate("update");
  };

  return (
    <Box className={classes.pageRoot}>
      <Box className={classes.topAreaEvents}>
        <Typography className={classes.topAreaEventsHeading}>
          {templateList?.data?._metadata?.total || ""} Alert Templates
        </Typography>
      </Box>
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={
            templateList?.data?.alertTemplates?.length
              ? templateList?.data?.alertTemplates
              : []
          }
          columns={columns}
          loading={templateList.isLoading}
          rowHeight={56}
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowClick={(data) => handleOpenDetail(data.row)}
          onRowsScrollEnd={(e) => {
            if (
              templateList?.data?._metadata?.total > e.visibleRowsCount &&
              !templateList.isLoading
            ) {
              setFilters({
                ...filters,
                pageNumber: Number(e.visibleRowsCount / 25) + 1,
                pageSize: 25,
              });
            }
          }}
        />
      </Paper>
      <AddAlertTemplate
        open={openAddTemplate}
        setOpen={setOpenAddTemplate}
        templateData={openAddTemplate === "update" ? templateData : {}}
      />
    </Box>
  );
}
export default withStyles(styleSheet, {
  name: "AlertTemplatesStyle",
})(AlertTemplates);
