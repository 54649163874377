export const styleSheet = () => ({
  SearchSubHeader: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 5px !important",
  },
  searchHeading: {
    fontFamily: "AvenirNext !important",
    fontSize: "20px !important",
    fontWeight: "600 !important",
    color: "black !important",
    lineHeight: "21px !important",
    textDecoration: "none !important",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
  comingSoonHeading: {
    fontFamily: "AvenirNext !important",
    fontSize: "18px !important",
    fontWeight: "600 !important",
    color: "black !important",
    lineHeight: "21px !important",
    textAlign: "center !important",
    padding: "12px !important",
  },
  SearchSubHeaderContent: {
    fontFamily: "AvenirNext !important",
    fontSize: "13px !important",
    fontWeight: "600 !important",
    color: "black !important",
    textOverflow: "ellipsis !important",
    overflow: "hidden !important",
  },
  searchListArea: {
    padding: "20px 0px !important",
  },
  searchListItemHeading: {
    fontFamily: "AvenirNext !important",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    flex: 1,
    whiteSpace: "nowrap  !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis  !important",
    textTransform: "capitalize !important",
    textDecoration: "none !important",
    color: "black !important",
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
  searchListItemButton: {
    backgroundColor: "black !important",
    color: "white !important",
    fontFamily: "AvenirNext !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  searchListItemHeader: {
    display: "flex !important",
    width: "100% !important",
  },
  searchSubHeading: {
    fontFamily: "AvenirNext !important",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    marginTop: "4px !important",
    color: "rgba(0, 0, 0, 0.60)  !important",
  },
  searchContent: {
    fontFamily: "AvenirNext !important",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    color: "rgba(0, 0, 0, 0.40) !important",
  },
  StatsAreaOne: {
    display: "flex !important",
    justifyContent: "flex-start  !important",
    alignItems: "center  !important",
    flexWrap: "wrap  !important",
    gap: "4px 8px  !important",
    marginTop: "5px  !important",
  },
  searchListItemArea: {
    marginBottom: "15px !important",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important",
    width: "100% !important",
    borderRadius: "15px !important",
    display: "flex",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    "&:hover": {
      "& .editIconAreaHover": {
        display: "block !important",
      },
      "& .copyItemLink": {
        display: "flex !important",
        padding: "0px 5px !important",
      },
      "& .copyItemLinkBottom": {
        visibility: "visible !important",
      },
    },
  },
  searchListItem: {
    padding: "12px !important",
    paddingTop: "7px !important",
    borderRadius: "15px !important",
    backgroundColor: "white !important",
    position: "relative !important",
  },
  searchListItemContent: {
    writingMode: "vertical-rl !important",
    color: "white !important",
    fontFamily: "AvenirNext !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    transform: "rotate(-180deg) !important",
    marginRight: "1px !important",
    textTransform: "capitalize !important",
  },
  editIconArea: {
    position: "absolute !important",
    right: "4px !important",
    bottom: "4px !important",
    display: "none !important",
    backgroundColor: "white !important",
  },
  addNewSearchButton: {
    marginTop: "20px !important",
    display: "flex !important",
    justifyContent: "center  !important",
    alignItems: "center  !important",
  },
  saveSearchArea: {
    paddingTop: "30px !important",
    paddingBottom: "20px !important",
  },
  fieldLabel: {
    fontFamily: "AvenirNext !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
    marginBottom: "8px !important",
  },
  saveButtonArea: {
    marginTop: "30px !important",
    display: "flex  !important",
    gap: "0px 10px !important",
  },
  searchDeleteButton: {
    backgroundColor: "#f85f69 !important",
    color: "white !important",
    fontFamily: "AvenirNext !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
  },
  searchSaveButton: {
    flex: 1,
    whiteSpace: "nowrap !important",
    backgroundColor: "black !important",
    color: "white !important",
    fontFamily: "AvenirNext !important",
    fontSize: "13px !important",
    minWidth: "120px !important",
    fontWeight: "500 !important",
  },
  lastVisitItem: {
    fontFamily: "AvenirNext !important",
    fontSize: "12px !important",
    fontWeight: "400 !important",
    color: "black !important",
    lineHeight: "21px !important",
    whiteSpace: "nowrap !important",
    "& span": {
      fontWeight: "800 !important",
    },
  },
  lastVisitAreaMb: {
    display: "block !important",
    marginBottom: "-12px !important",
    padding: "5px !important",
  },
  lastVisitArea: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    padding: "3px 5px !important",
  },
  StatsAreaOneHide: {
    display: "none !important",
  },
  copyLinkAreaMb: {
    display: "flex !important",
  },
  copyLinkAreaMbHide: {
    display: "none !important",
  },
  copyLinkAreaLg: {
    display: "flex !important",
  },
  copyLinkAreaLgHide: {
    display: "none !important",
  },
  copyLinkArea: {
    color: "#03A9F4 !important",
    display: "none !important",
    gap: "0px 5px !important",
    textTransform: "capitalize !important",
    marginTop: "-3px !important",
    paddingLeft: "0px !important",
  },
  copyLinkAreaTop: {
    color: "#03A9F4 !important",
    gap: "0px 5px !important",
    textTransform: "capitalize !important",
    marginTop: "-3px !important",
    paddingLeft: "0px !important",
    visibility: "hidden !important",
  },
  copyLinkAreaBottom: {
    color: "#03A9F4 !important",
    display: "flex !important",
    gap: "0px 5px !important",
    textTransform: "capitalize !important",
    paddingLeft: "0px !important",
    visibility: "hidden !important",
  },
  copyLinkAreaContent: {
    color: "#03A9F4 !important",
    fontFamily: "AvenirNext !important",
    fontSize: "12px !important",
    fontWeight: "400 !important",
  },
  selectedList: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    flexWrap: "wrap !important",
    gap: "10px !important",
    marginTop: "20px !important",
  },
  groupIconArea: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 7px !important",
    padding: "8px !important",
    fontWeight: "600 !important",
    fontFamily: "AvenirNext !important",
    "& svg": {
      fontSize: "20px !important",
    },
  },
  groupItem: {
    color: "black !important",
    fontFamily: "AvenirNext  !important",
    fontSize: "14px !important",
  },
  propertyTypeAreaItems: {
    display: "flex  !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "10px !important",
    flexWrap: "wrap !important",
  },
  propertyTypeAreaItemDes: {
    fontSize: "13px !important",
    whiteSpace: "nowrap !important",
    color: "#666666 !important",
    padding: "4px 10px!important",
    borderRight: "1px solid lightgrey!important",
    minWidth: "8ch!important",
    lineHeight: "25px!important",
    fontFamily: "AvenirNext !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
  },
  accountNotFound: {
    color: "#D20A0A !important",
    fontFamily: "AvenirNext !important",
    fontSize: "20px !important",
    fontWeight: "600 !important",
    lineHeight: "21px !important",
    textAlign: "center !important",
    padding: "15px 0px !important",
  },
  propertyTypeAreaItem: {
    display: "flex !important",
    height: "34px !important",
    borderRadius: "5px !important",
  },
  propertyTypeAreaItemActive: {
    display: "flex !important",
    height: "34px !important",
    background: "#F2F2F2 !important",
    borderRadius: "5px !important",
  },
  additionalFieldsButton: {
    color: "#0096EB !important",
    borderRadius: "3px !important",
    backgroundColor: "#EBF9FF !important",
    textTransform: "capitalize !important",
    fontSize: "14px !important",
  },
  additionFieldsArea: {
    transition: "height 1.5s ease-out",
    overflow: "hidden  !important",
    padding: "20px 0px !important",
    "@media (min-width: 1500px)": {
      width: "calc(100% - 40px)",
    },
    "@media (max-width: 1500px)": {
      width: "calc(100% - 20px)",
    },
  },
  additionalFieldsCard: {
    border: "1px solid rgba(0, 0, 0, 0.30) !important",
    borderRadius: "4px !important",
    backgroundColor: "white !important",
    // padding: "10px !important",
  },
  additionalFieldsList: {
    maxHeight: "220px !important",
    overflow: "auto !important",
    "&::-webkit-scrollbar-thumb": {
      background: "#A8A8A8",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "8px",
      borderRadius: "10px",
    },
  },
  additionalFieldsItem: {
    marginTop: "20px !important",
  },
  selectedItem: {
    backgroundColor: "#EBEBEB !important",
  },
  additionFieldsHeading: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
    color: "rgba(0, 0, 0, 0.6) !important",
  },
  savedSearchArea: {
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "& .Mui-selected": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important",
    },
  },
  searchSubHeadingArea: {
    display: "flex  !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "2px 10px !important",
    flexWrap: "wrap !important",
  },
  topSearchCard: {
    borderRadius: "15px !important",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)  !important",
    padding: "12px !important",
    "&:hover": {
      "& .copyLinkArea1": {
        visibility: "visible !important",
      },
    },
  },
  createAccountArea: {
    padding: "15px 0px !important",
  },
  sitesListAreaItem: {
    borderRadius: "15px !important",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)  !important",
    padding: "12px !important",
    marginBottom: "15px !important",
    border: "1px solid white !important",
    cursor: "pointer !important",
    "&:hover": {
      border: "1px solid lightgrey !important",
    },
  },
  site_domain: {
    color: "black !important",
    fontFamily: "AvenirNext !important",
    fontSize: "18px !important",
    fontWeight: "600 !important",
    lineHeight: "21px !important",
    textAlign: "center !important",
    padding: "10px 0px !important",
  },
  siteLogoArea: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    alignItems: "center !important",
    "& img": {
      width: "60px !important",
      height: "20px !important",
      objectFit: "contain",
    },
  },
  multipleSiteArea: {
    padding: "15px 0px !important",
  },
  multipleSiteAreaContent: {
    color: "black !important",
    fontFamily: "AvenirNext !important",
    fontSize: "14px !important",
    fontWeight: "500 !important",
    marginBottom: "10px !important",
  },
  switchSection: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    marginBottom: "-15px !important",
  },
  switchSectionInner: {
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important",
    gap: "0px 5px  !important",
  },
  sentViaArea: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    alignItems: "center !important",
    gap: "0px 10px  !important",
  },
  sentViaAreaLabel: {
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.50) !important",
  },
  propertiesListingArea: {
    height: "500px  !important",
    overflow: "auto  !important",
    marginTop: "20px !important",
    paddingRight: "7px !important",
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(54, 131, 188, 0.6)  !important",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ccc  !important",
    },
    "&::-webkit-scrollbar": {
      width: "6px  !important",
    },
  },
  propertyItem: {
    // border: "1px solid grey  !important",
    borderRadius: "6px  !important",
    marginBottom: "5px  !important",
    overflow: "hidden  !important",
    display: "flex !important",
    justifyContent: "flex-start !important",
    cursor: "pointer !important",
    width: "100%   !important",
  },
  propertyItemDes: {
    flex: 1,
    padding: "5px !important",
  },
  propertyItemDesHeading: {
    fontFamily: "AvenirNext !important",
    fontSize: "12px !important",
    fontWeight: "700 !important",
    whiteSpace: "nowrap  !important",
    width: "140px   !important",
    overflow: "hidden   !important",
    textOverflow: "ellipsis   !important",
  },
  propertyItemDesContent: {
    fontFamily: "AvenirNext !important",
    fontSize: "11px !important",
    fontWeight: "500 !important",
    whiteSpace: "nowrap  !important",
    width: "140px   !important",
    overflow: "hidden   !important",
    textOverflow: "ellipsis   !important",
  },
  propertyResultsItemPrice: {
    fontFamily: "AvenirNext !important",
    fontSize: "12px !important",
    fontWeight: "700 !important",
    whiteSpace: "nowrap  !important",
    width: "140px   !important",
    overflow: "hidden   !important",
    textOverflow: "ellipsis   !important",
    marginTop: "5px  !important",
  },
  propertyResultsItemStatsValue: {
    fontFamily: "AvenirNext !important",
    fontSize: "10px !important",
    fontWeight: "700 !important",
    whiteSpace: "nowrap  !important",
    overflow: "hidden   !important",
    textOverflow: "ellipsis   !important",
    display: "inline !important",
    marginRight: "2px  !important",
  },
  propertyResultsItemSubTitle: {
    fontFamily: "AvenirNext !important",
    fontSize: "10px !important",
    fontWeight: "500 !important",
    whiteSpace: "nowrap  !important",
    overflow: "hidden   !important",
    textOverflow: "ellipsis   !important",
    display: "inline !important",
  },
});
