import React from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import {
  Box,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  ListItemAvatar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { changeProjectStatus } from "../../../redux/projects/projects";
import DataGridComponent from "../../projects/DataGridComponent";
import DayChip from "./DayChip";
import NoImage from "../../../assets/images/noimage.png";
import ProjectDetails from "../../projects/projects/ProjectDetails";
import TaskDetails from "../../projects/tasks/TaskDetails";
import ChangeStatusAlertModal from "./changeStatusAlertModal";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import Avatars from "../../../components/uiElements/avatar/index";

import { styleSheet } from "./style";
import {
  closeProjectDetails,
  closeTaskDetails,
  openProjectDetails,
  openTaskDetails,
  setSelectedProjectId,
} from "../../../redux/projects/project";

const useStyles = makeStyles(styleSheet);
const projectOpenHouseKey = [
  "Who is Hosting",
  "Start Time",
  "End Time",
  "Date of Open House",
];

function OpenHouseTable(props) {
  let { setFilters, filters } = props;
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  const [alertModal, setAlertModal] = React.useState(false);
  const [projectID, setProjectID] = React.useState(null);
  const [openComments, setOpenComments] = React.useState(true);
  const agentList = useSelector((item) =>
    item.users.storedListOfUsers.list.filter((item) => item.is_agent === true)
  );
  const {
    isProjectDetailsOpen,
    isTaskDetailsOpen,
    selectedProjectId,
    selectedTaskId,
  } = useSelector((state) => state.project);
  const { projects, totalProjects, projectsLoading } = useSelector(
    (state) => state.project
  );

  const columns = [
    {
      field: "image",
      headerName: "Image",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "columns",
            }}
          >
            <img
              width="100px"
              height="70px"
              style={{ borderRadius: "5px" }}
              src={
                params.row?.listing_data?.property_img
                  ? params.row?.listing_data?.property_img
                  : NoImage
              }
              alt="open-project-img"
            />
          </Box>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    {
      field: "address",
      headerName: "Address",
      renderCell: (params) => {
        return (
          <Box>
            <Typography
              variant="h6"
              sx={{ fontWeight: "500", fontSize: "15px" }}
            >
              {params.row?.listing_data?.street}
            </Typography>
            {params.row?.listing_data?.city &&
              params.row?.listing_data?.state && (
                <Typography variant="span">
                  {params.row?.listing_data?.city},{" "}
                  {params.row?.listing_data?.state}{" "}
                  {params.row?.listing_data?.zipcode}
                </Typography>
              )}
          </Box>
        );
      },
      minWidth: 200,
      flex: 1,
      sortable: true,
    },
    {
      field: "agent",
      headerName: "List Agent",
      sortComparator: (v1, v2) => v1.first_name.localeCompare(v2.first_name),
      renderCell: ({ row }) => {
        let agentDetail = agentList.find(
          (agent) => agent.id === row?.listing_data?.agent_id
        );
        return (
          <ListItem
            alignItems="flex-start"
            sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
          >
            <ListItemAvatar>
              <Avatars
                sx={{ marginRight: "10px", borderRadius: "4px" }}
                src={
                  agentDetail?.profile_images?.profile_img ||
                  agentDetail?.profile_images?.profile_img_thumbnail
                }
                variant="square"
              >{`${agentDetail?.first_name?.slice(
                0,
                1
              )}${agentDetail?.last_name?.slice(0, 1)}`}</Avatars>
            </ListItemAvatar>
            <ListItemText
              primary={agentDetail?.first_name}
              secondary={
                <React.Fragment>{agentDetail?.last_name}</React.Fragment>
              }
            />
          </ListItem>
        );
      },
      minWidth: 160,
      flex: 1,
      sortable: true,
    },
    {
      field: "hostAgent",
      headerName: "Host Agent",
      sortComparator: (v1, v2) => v1.first_name.localeCompare(v2.first_name),
      renderCell: ({ row }) => {
        const projectOpenHouseValue = {};
        row.project_other_data.forEach((otherData) => {
          if (otherData.data_field.name === "Who is Hosting") {
            projectOpenHouseValue[otherData.data_field.name] = otherData.value;
          }
        });
        const hostAgentName = projectOpenHouseValue["Who is Hosting"];
        const hostAgent = agentList.find(
          (agent) => agent.full_name === hostAgentName
        );

        if (hostAgent) {
          return (
            <ListItem
              alignItems="flex-start"
              sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
            >
              <ListItemAvatar>
                <Avatars
                  sx={{ marginRight: "10px", borderRadius: "4px" }}
                  src={hostAgent?.profile_images?.profile_img_thumbnail}
                  variant="square"
                >{`${hostAgent.first_name?.slice(
                  0,
                  1
                )}${hostAgent.last_name?.slice(0, 1)}`}</Avatars>
              </ListItemAvatar>
              <ListItemText
                primary={hostAgent.first_name}
                secondary={
                  <React.Fragment>{hostAgent.last_name}</React.Fragment>
                }
              />
            </ListItem>
          );
        } else return "";
      },
      minWidth: 160,
      flex: 1,
      sortable: true,
    },
    {
      field: "dateOfOpenHouse",
      sortComparator: (d1, d2) => {
        var date1 = moment(d1);
        var date2 = moment(d2);

        if (date1 > date2) {
          return 1;
        } else if (date1 < date2) {
          return -1;
        } else return 0;
      },
      type: "date",
      headerName: "Date",
      renderCell: ({ row }) => {
        const projectOpenHouseValue = {};
        row.project_other_data.forEach((otherData) => {
          if (projectOpenHouseKey.includes(otherData.data_field.name)) {
            projectOpenHouseValue[otherData.data_field.name] = otherData.value;
          }
        });
        const dateOfOpenHouse = projectOpenHouseValue["Date of Open House"];
        return <>{dateOfOpenHouse}</>;
      },
      minWidth: 100,
      sortable: true,
    },
    {
      field: "dayOfTheWeek",
      headerName: "Day of the Week",
      sortComparator: (d1, d2) => {
        const sorterValue = {
          sunday: 0,
          monday: 1,
          tuesday: 2,
          wednesday: 3,
          thursday: 4,
          friday: 5,
          saturday: 6,
        };

        let day1 = d1.toLowerCase();
        let day2 = d2.toLowerCase();
        return sorterValue[day1] - sorterValue[day2];
      },
      renderCell: ({ row }) => {
        const projectOpenHouseValue = {};
        row.project_other_data.forEach((otherData) => {
          if (projectOpenHouseKey.includes(otherData.data_field.name)) {
            projectOpenHouseValue[otherData.data_field.name] = otherData.value;
          }
        });
        const dayOfTheWeek = moment(
          projectOpenHouseValue["Date of Open House"]
        ).format("dddd");
        return <DayChip value={dayOfTheWeek} />;
      },
      minWidth: 130,
      sortable: true,
    },
    {
      field: "time",
      headerName: "Time",
      renderCell: ({ row }) => {
        const projectOpenHouseValue = {};

        row.project_other_data.forEach((otherData) => {
          if (projectOpenHouseKey.includes(otherData.data_field.name)) {
            projectOpenHouseValue[otherData.data_field.name] = otherData.value;
          }
        });
        const startTime = projectOpenHouseValue["Start Time"]
          ? moment(projectOpenHouseValue["Start Time"], "hh:mm a").format("LT")
          : "";
        const endTime = projectOpenHouseValue["End Time"]
          ? moment(projectOpenHouseValue["End Time"], "hh:mm a").format("LT")
          : "";
        return (
          <>
            {startTime} - {endTime}{" "}
          </>
        );
      },
      minWidth: 100,
      sortable: false,
      flex: 1,
    },
    {
      field: "taskCompletion",
      headerName: "Tasks",
      renderCell: ({ row }) => {
        let totalTasks = row.project_tasks.length;
        let totalTasksCompl = row.project_tasks.filter((tsk) =>
          ["review", "approved", "submitted", "completed"].includes(
            tsk.status.toLowerCase()
          )
        ).length;
        const taskCompletion =
          totalTasksCompl !== 0
            ? parseInt((totalTasksCompl / totalTasks) * 100)
            : 0;
        return <CircularProgressWithLabel value={taskCompletion} />;
      },
      minWidth: 50,
      sortable: false,
    },
    {
      field: "actions",
      minWidth: 140,
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <Box display="flex" justifyContent="center" gap="15px" width={"100%"}>
            <Tooltip title="Project Details">
              <IconButton
                size="small"
                aria-label="edit-project"
                onClick={() => handleOpenProject(params.id)}
                className={classes.editProjectButton}
              >
                <BorderColorIcon />
              </IconButton>
            </Tooltip>

            {params.row.selectedOpenHouseTab !== 2 && (
              <Tooltip title="Cancel Project">
                <IconButton
                  size="small"
                  aria-label="cancel-project"
                  onClick={() => {
                    setProjectID(params.id);
                    setAlertModal(true);
                  }}
                  className={classes.cancelProjectButton}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
      sortable: false,
    },
  ];

  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleOpenProject = (id) => {
    dispatch(setSelectedProjectId(id));
    dispatch(openProjectDetails(true));
  };

  const handleChangeStatus = () => {
    dispatch(changeProjectStatus({ projectID, status: "canceled" }));
    closeAlertModal();
  };

  const closeAlertModal = () => {
    setAlertModal(false);
    setProjectID(null);
  };

  const setOpenTaskDetails = (flag) => {
    flag ? dispatch(openTaskDetails(true)) : dispatch(closeTaskDetails(false));
  };

  const setOpenProjectDetails = (flag) => {
    flag
      ? dispatch(openProjectDetails(true))
      : dispatch(closeProjectDetails(false));
  };
  /**
   * End Region: Helpers
   */

  return (
    <Box className={classes.container}>
      <ChangeStatusAlertModal
        alertModal={alertModal}
        handleChangeStatus={handleChangeStatus}
        closeAlertModal={closeAlertModal}
        classes={classes}
      />
      {isProjectDetailsOpen && selectedProjectId && (
        <ProjectDetails
          projectID={selectedProjectId}
          openComments={openComments}
          setOpenComments={setOpenComments}
          open={isProjectDetailsOpen}
          setOpen={setOpenProjectDetails}
        />
      )}
      {isTaskDetailsOpen && selectedTaskId && (
        <TaskDetails
          taskID={selectedTaskId}
          openComments={openComments}
          setOpenComments={setOpenComments}
          open={isTaskDetailsOpen}
          setOpen={setOpenTaskDetails}
        />
      )}
      <Paper className={classes.tableRoot}>
        <DataGridComponent
          rows={projects || []}
          columns={columns}
          rowHeight={85}
          loading={projectsLoading}
          rowCount={totalProjects}
          onRowsScrollEnd={(e) => {
            if (totalProjects > e.visibleRowsCount && !projectsLoading) {
              setFilters({ ...filters, pageNumber: filters.pageNumber + 1 });
            }
          }}
          disableColumnMenu
          initialState={{
            sorting: {
              sortModel: [{ field: "dateOfOpenHouse", sort: "desc" }],
            },
          }}
        />
      </Paper>
    </Box>
  );
}

export default OpenHouseTable;
