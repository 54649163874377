import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { DataGridPro } from "@mui/x-data-grid-pro";
import TableSkeleton from "../../../components/loader/tableLoader";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Link } from "react-router-dom";
import {
  getTaskTemplates,
  deleteTaskTemplate,
} from "../../../redux/transactions/taskTemplates";
import ConfirmModal from "../../../components/widgets/modals/ConfirmModal";
import ResponseAlert from "../../../components/responseAlert";
function TemplateList(props) {
  let { classes } = props;
  const [search, setSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [templateDetail, setTemplateDetail] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const templateData = useSelector(
    (item) => item.transactions.templates.templateData
  );
  const deleteTemplate = useSelector(
    (item) => item.transactions.templates.deleteTemplate
  );
  const columns = [
    {
      field: "title",
      headerName: "Template Name",
      minWidth: 300,
      editable: false,
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ color: "#0288D1", fontSize: "14px" }}>
            {params?.row?.title}
          </Typography>
        );
      },
    },
    {
      field: "tasks",
      headerName: "# of tasks",
      width: 120,
      editable: false,
      sortable: true,
      renderCell: (params) => {
        return (
          <Typography sx={{ color: "#0288D1", fontSize: "14px" }}>
            {params?.row?.tasks?.length || "0"}
          </Typography>
        );
      },
    },
    {
      field: "Owner",
      headerName: "Created By",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {params.row.createdById
              ? agentRosterList?.list.find(
                  (item) => item.id === Number(params.row.createdById || 1)
                )?.full_name
              : ""}
          </Typography>
        );
      },
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      renderCell: (params) => {
        return (
          <>
            <Grid container>
              <IconButton
                onClick={() =>
                  navigate(`/transaction/task_templates/update`, {
                    state: params.row,
                  })
                }
              >
                {" "}
                <EditIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setTemplateDetail({ ...params.row });
                  setOpen(true);
                }}
              >
                {" "}
                <DeleteRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
              </IconButton>
            </Grid>
          </>
        );
      },
      type: "actions",
      width: 150,
      editable: false,
      sortable: false,
    },
  ];

  useEffect(() => {
    dispatch(getTaskTemplates({}));
  }, []);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(() => {
        dispatch(
          getTaskTemplates({
            pageNumber: 1,
            search: search,
          })
        );
      }, 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [search]);
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "Transaction template  deleted successfully!",
      errorType: "success",
      isOpen: true,
    });
    setOpen(false);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
    setOpen(false);
  };
  const handleConfirm = () => {
    dispatch(
      deleteTaskTemplate({ id: templateDetail.id, handleError, handleSuccess })
    );
  };

  return (
    <Box className={classes.pageRoot}>
      <Box className={classes.templateListHeader}>
        <Typography>
          {templateData?.metadata?.total} Transaction Task Templates
        </Typography>
        <Box className={classes.templateListHeaderRight}>
          {" "}
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<AddRoundedIcon />}
            className={classes.addTemplateButton}
            to={`/transaction/task_templates/create`}
            component={Link}
          >
            New Template
          </Button>
          <TextField
            placeholder="Search Templates"
            type="search"
            size="small"
            sx={{ "& .MuiInputBase-root": { height: "37.5px" } }}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
      </Box>
      <Paper className={classes.tableRoot}>
        <DataGridPro
          rows={
            templateData?.data || []
            /* [
            {
              id: 1,
              subject: "Buyer - Cash",
              createdById: 40,
              tasks: 10,
            },
            {
              id: 2,
              subject: "Buyer - Not Cash",
              createdById: 42,
              tasks: 15,
            },
          ] */
          }
          columns={columns}
          loading={templateData.isLoading}
          rowHeight={56}
          checkboxSelection
          components={{
            LoadingOverlay: TableSkeleton,
          }}
          onRowsScrollEnd={(e) => {
            if (templateData?.metadata?.total > e.visibleRowsCount) {
              dispatch(
                getTaskTemplates({
                  pageNumber: Number(e.visibleRowsCount / 25) + 1,
                  search: search,
                })
              );
            }
          }}
        />
      </Paper>
      <ConfirmModal
        open={open}
        setOpen={setOpen}
        title={`Are you sure to delete template "${templateDetail?.title}"?`}
        handleConfirm={handleConfirm}
        loading={deleteTemplate.isLoading}
      />
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "TemplateListStyle" })(
  TemplateList
);
