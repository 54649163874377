import React, { useState } from "react";
import { Container, Grid, Box, Card, CardHeader, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { styleSheet } from "./style.js";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AddPhoneNumber from "../modals/addPhoneNumber";
import { getAllPhones } from "../../../redux/phoneConfiguration/phoneNumbers";
import { getAllPhoneExtensions } from "../../../redux/phoneConfiguration/extensions";
import { getAllQueue } from "../../../redux/phoneConfiguration/queue";
import UpdatePhoneNumber from "../modals/updatePhoneNumber";
import TableSkeleton from "../../../components/loader/tableLoader";

const PhoneNumbersList = (props) => {
  let { classes, search } = props;
  const dispatch = useDispatch();
  const [openAddPhone, setOpenAddPhone] = useState(false);
  const [openUpdatePhone, setOpenUpdatePhone] = useState(false);
  const phonesList = useSelector(
    (item) => item.phoneConfiguration.PhoneNumbers.phonesList
  );
  const extensionsList = useSelector(
    (item) => item.phoneConfiguration.PhoneExtensions.extensionsList
  );
  const queueList = useSelector(
    (item) => item.phoneConfiguration.PhoneQueues.queueList
  );

  const { isLoading } = phonesList;

  useEffect(() => {
    if (phonesList?.data?.length === 0 && !phonesList.isLoading) {
      dispatch(getAllPhones({}));
    }
  }, []);

  // const handleGetLatestData=()=>{
  //   if (!phonesList.isLoading) {
  //     dispatch(getAllPhones({}));
  //     }
  // }
  //   useEffect(() => {
  //   const id = setInterval(handleGetLatestData, 300000);
  //   return () => clearInterval(id);
  //   }, []);

  useEffect(() => {
    if (extensionsList?.data?.length === 0 && !extensionsList.isLoading) {
      dispatch(getAllPhoneExtensions({}));
    }
    if (queueList?.data?.length === 0 && !queueList.isLoading) {
      dispatch(getAllQueue({}));
    }
  }, []);

  const columns = [
    {
      field: "number",
      headerName: "Number",
      renderCell: ({ row }) => {
        return <Box>{row?.number}</Box>;
      },
      width: 180,
      editable: false,
      sortable: true,
    },
    {
      field: "Prefix",
      headerName: "Prefix",
      renderCell: ({ row }) => {
        return <Box>{row?.prefix_label}</Box>;
      },
      width: 220,
      editable: false,
      sortable: true,
    },

    {
      field: "name",
      headerName: "Queue/Extension",
      renderCell: ({ row }) => {
        return (
          <Box>
            {row.twilio_call_queue?.name
              ? `${row.twilio_call_queue?.name} (Queue)`
              : null}{" "}
            {row.twilio_call_extension?.name
              ? `${row.twilio_call_extension?.name} - ${row.twilio_call_extension?.users_v2?.first_name} ${row.twilio_call_extension?.users_v2?.last_name} (Extension)`
              : null}
          </Box>
        );
      },
      minWidth: 260,
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "LastActivity",
      headerName: "Last Activity",
      renderCell: ({ row }) => {
        return row?.last_call?.fromNumber ? (
          <Box>
            {moment(row.updatedAt).format("l")}{" "}
            {moment(row.updatedAt).format("LT")} from{" "}
            {row?.last_call?.fromNumber}
          </Box>
        ) : (
          ""
        );
      },
      minWidth: 260,
      type: "dateTime",
      editable: false,
      sortable: false,
      flex: 1,
    },
    {
      field: "Action",
      headerName: "Action",
      type: "actions",
      renderCell: ({ row }) => {
        return (
          <Button
            variant="contained"
            size="small"
            color="inherit"
            sx={{
              backgroundColor: "white",
              color: "#000000",
              fontWeight: "400",
            }}
            onClick={() => setOpenUpdatePhone(row)}
          >
            Edit number
          </Button>
        );
      },
      width: 250,
      editable: false,
      sortable: false,
    },
  ];
  return (
    <>
      <Container maxWidth="lg">
        <div className={classes.pageRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card>
                <CardHeader
                  classes={{ title: classes.pageHeading }}
                  title={
                    <Box className={classes.pageHeading}>
                      Phone Numbers{" "}
                      <Button
                        onClick={() => setOpenAddPhone(true)}
                        classes={{ root: classes.addPhoneButton }}
                      >
                        Add Number
                      </Button>
                    </Box>
                  }
                />
                <Box className={classes.tableRoot}>
                  <DataGridPro
                    columns={columns}
                    rows={phonesList.data ? phonesList.data : []}
                    loading={isLoading}
                    components={{
                      LoadingOverlay: TableSkeleton,
                    }}
                    rowHeight={56}
                    // checkboxSelection
                    // onRowClick={(param) => onRowClick(param)}
                    // onRowsScrollEnd={(e) => {
                    //   if (totalAgents > e.visibleRowsCount) {
                    //     dispatch(
                    //       getAgentRosterList({
                    //         pageNumber: Number(e.visibleRowsCount / 25)+1,
                    //         pageSize: 25,
                    //         only_agents:true
                    //       })
                    //     );
                    //   }
                    // }}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Container>
      <UpdatePhoneNumber
        open={openUpdatePhone}
        extensionsList={extensionsList?.data}
        queueList={queueList?.data}
        setOpen={setOpenUpdatePhone}
      />
      <AddPhoneNumber
        open={openAddPhone}
        extensionsList={extensionsList?.data}
        queueList={queueList?.data}
        setOpen={setOpenAddPhone}
      />
    </>
  );
};

export default withStyles(styleSheet, { name: "PhoneNumbersListStyle" })(
  PhoneNumbersList
);
