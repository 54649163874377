import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Stack,
  ListItemText,
  Menu,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddAccountModal from "../modals/addAccount";
import { useDispatch, useSelector } from "react-redux";
import { getUserPasswords } from "../../../../redux/agents/agentRosterDetail/password/getUserPassword";
import TableSkeleton from "../../../../components/loader/tableLoader.jsx";
import moment from "moment";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import { deleteAccountPassword } from "../../../../redux/agents/agentRosterDetail/password";
const AgentRosterPasswords = ({ classes }) => {
  const { id } = useParams();
  const [addPasswordOpen, setAddPasswordOpen] = useState(false);
  const [accountData, setAccountData] = useState({});
  const [openMenu, setOpenMenu] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [filters, setFilters] = useState({
    id: id,
    pageNumber: 1,
    pageSize: 15,
  });
  const dispatch = useDispatch();
  const passwordList = useSelector(
    (state) => state.agentRoster.AgentPasswords.passwordList
  );
  const deletePassword = useSelector(
    (state) => state.agentRoster.AgentPasswords.deletePassword
  );

  useEffect(() => {
    if (deletePassword.success && !deletePassword.isLoading && openDelete) {
      setErrorAlert({
        errorMsg: "You have successfully deleted the account password",
        errorType: "success",
        isOpen: true,
      });
      setOpenDelete(false);
      setAccountData({});
    } else if (
      !deletePassword.isLoading &&
      deletePassword.errMsg &&
      openDelete
    ) {
      setErrorAlert({
        errorMsg: JSON.stringify(deletePassword.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [deletePassword.isLoading]);

  const columns = [
    {
      field: "website_title",
      headerName: "Site Name",
      renderCell: ({ row }) => {
        return (
          <ListItemText
            primary={row.website_title}
            secondary={
              <a
                style={{ color: "rgba(0, 0, 0, 0.6)", textDecoration: "none" }}
                target="_blank"
                href={row.website_link}
              >
                {row.website_link}
              </a>
            }
          />
        );
      },
      minWidth: 245,
      editable: false,
      sortable: true,
      flex: 1,
    },
    {
      field: "username",
      headerName: "User Name",
      renderCell: ({ row }) => {
        return (
          <Stack
            sx={{
              width: "100%",
              "& .custom-copy-button": { display: "none" },
              "&:hover": { "& .custom-copy-button": { display: "block" } },
            }}
            direction="row"
            spacing={2}
            alignItems={"center"}
          >
            <Box>{row.username}</Box>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(row.username);
                setErrorAlert({
                  errorMsg: "User name copied successfully!",
                  errorType: "success",
                  isOpen: true,
                });
              }}
              className="custom-copy-button"
            >
              <ContentCopyRoundedIcon />
            </IconButton>
          </Stack>
        );
      },
      minWidth: 120,
      flex: 0.5,
      editable: false,
      sortable: true,
    },
    {
      field: "password",
      headerName: "Password",
      renderCell: ({ row }) => {
        return (
          <Stack
            sx={{
              width: "100%",
              "& .custom-copy-button": { display: "none" },
              "&:hover": { "& .custom-copy-button": { display: "block" } },
            }}
            direction="row"
            spacing={2}
            alignItems={"center"}
          >
            <Box>{row.password}</Box>
            <IconButton
              onClick={() => {
                navigator.clipboard.writeText(row.password);
                setErrorAlert({
                  errorMsg: "Password copied successfully!",
                  errorType: "success",
                  isOpen: true,
                });
              }}
              className="custom-copy-button"
            >
              <ContentCopyRoundedIcon />
            </IconButton>
          </Stack>
        );
      },
      minWidth: 100,
      editable: false,
      sortable: true,
      flex: 0.5,
    },
    {
      field: "updatedAt",
      headerName: "Last Updated",
      renderCell: ({ row }) => {
        return (
          <Box>
            {moment(moment(new Date(row?.updatedAt).toString())).from(
              moment(new Date())
            )}
          </Box>
        );
      },
      minWidth: 120,
      editable: false,
      sortable: true,
      flex: 0.5,
    },
    {
      headerName: "",
      type: "actions",
      renderCell: ({ row }) => {
        // console.log(params);
        return (
          <Box
            onClick={(e) => {
              setAccountData(row);
              setOpenMenu(e.currentTarget);
            }}
            sx={{ display: "flex", justifyItems: "center" }}
          >
            <IconButton>
              <MoreHorizRoundedIcon />
            </IconButton>
          </Box>
        );
      },
      width: 120,
    },
  ];

  useEffect(() => {
    dispatch(getUserPasswords(filters));
  }, [filters]);
  const handleDeleteAccount = () => {
    dispatch(deleteAccountPassword(accountData));
  };
  return (
    <React.Fragment>
      <Card
        variant="outlined"
        sx={{ backgroundColor: "white", padding: "15px 15px 15px 10px" }}
        className={classes.pageRootPayment}
      >
        <CardHeader
          classes={{ title: classes.pageHeading }}
          title=""
          action={
            <Button
              onClick={() => {
                setAddPasswordOpen(true);
                setAccountData({});
              }}
              size={"small"}
              variant="contained"
              className={classes.addAccountButton}
              endIcon={<AddIcon />}
            >
              Add Account
            </Button>
          }
        />
        <CardContent>
          <Box className={classes.tableRoot}>
            <DataGridPro
              rows={
                passwordList.data?.userPasswords?.length
                  ? passwordList.data?.userPasswords
                  : []
              }
              columns={columns}
              onRowsScrollEnd={(e) => {
                if (
                  passwordList.data?.count > e.visibleRowsCount &&
                  !passwordList.isLoading
                ) {
                  setFilters({
                    ...filters,
                    pageNumber: Number(e.visibleRowsCount / 15) + 1,
                    pageSize: 15,
                  });
                }
              }}
              loading={passwordList.isLoading}
              components={{
                LoadingOverlay: TableSkeleton,
              }}
              disableSelectionOnClick
              rowHeight={56}
            />
          </Box>
        </CardContent>
      </Card>
      <AddAccountModal
        open={addPasswordOpen}
        accountData={accountData}
        setOpen={setAddPasswordOpen}
      />
      <Menu
        anchorEl={openMenu}
        id="password-dropdown-menu"
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(false)}
        sx={{ zIndex: 10000000 }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <List sx={{ width: "100%", maxWidth: 360 }} disablePadding>
          <ListItem
            onClick={() => {
              setAddPasswordOpen("update");
              setOpenMenu(false);
            }}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: "40px !important" }}>
                <BorderColorIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Edit Details" />
            </ListItemButton>
          </ListItem>
          <Divider component="li" />
          <ListItem
            onClick={() => {
              setOpenMenu(false);
              setOpenDelete(true);
            }}
            disablePadding
          >
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: "40px !important" }}>
                <DeleteIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText primary="Delete Account" />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
      <Dialog
        disablePortal
        style={{ position: "absolute" }}
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        sx={{ "& .MuiPaper-root": { overflow: "visible !important" } }}
      >
        <DialogContent sx={{ width: "370px", textAlign: "center", mt: "20px" }}>
          Are you sure you want to delete the account "
          {accountData.website_title}" ?
        </DialogContent>
        <DialogActions sx={{ mb: "20px", justifyContent: "center" }}>
          <Button
            onClick={() => {
              setOpenDelete(false);
            }}
            sx={{
              color: "#000000",
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <LoadingButton
            classes={{ root: classes.deleteButTon }}
            color="inherit"
            autoFocus
            loadingPosition="start"
            loading={deletePassword.isLoading}
            onClick={handleDeleteAccount}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
};

export default withStyles(styleSheet, {
  name: "AgentRosterPasswordsStyle",
})(AgentRosterPasswords);
