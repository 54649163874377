import { createSlice } from "@reduxjs/toolkit";
import { getPayments } from "./getPayments";
import { updatePaymentStatus } from "./updatePaymentStatus";
import { deletePayment } from "./deletePayment";
const initialState = {
  payments: {
    success: false,
    data: [],
    isLoading: false,
    errMsg: null,
    pendingPaymentAmount: 0,
    totalPaymentAmount: 0,
    count: 0,
  },
  updatePayment: {
    success: false,
    isLoading: false,
    data: [],
    errMsg: null,
  },
  delPayment: {
    success: false,
    isLoading: false,
    data: [],
    errMsg: null,
  },
};
const Payments = createSlice({
  name: "Payments",
  initialState,
  extraReducers: {
    [getPayments.pending]: (state) => {
      state.payments.isLoading = true;
      state.payments.errMsg = null;
      state.payments.success = false;
    },
    [getPayments.fulfilled]: (state, action) => {
      state.payments.success = true;
      state.payments.isLoading = false;
      if (state.payments?.data?.length && action.meta?.arg?.pageNumber > 1) {
        state.payments.data = [...state.payments?.data, ...action.payload.data];
      } else {
        state.payments.data = action.payload.data;
      }
      state.payments.count = action.payload.count;
      state.payments.pendingPaymentAmount = action.payload.pendingPaymentAmount;
      state.payments.totalPaymentAmount = action.payload.totalPaymentAmount;
    },
    [getPayments.rejected]: (state, action) => {
      state.payments.isLoading = false;
      state.payments.success = false;
      state.payments.errMsg = action.payload;
    },
    // update payment status
    [updatePaymentStatus.pending]: (state) => {
      state.updatePayment.isLoading = true;
      state.updatePayment.errMsg = null;
      state.updatePayment.success = false;
    },
    [updatePaymentStatus.fulfilled]: (state, action) => {
      state.updatePayment.success = true;
      state.updatePayment.isLoading = false;
      state.updatePayment.data = action.payload.data;
      let prePayments = [...state.payments.data];
      if (action.payload.paymentIds) {
        let updatePayments = prePayments?.map((item) => {
          if (action.payload.paymentIds.includes(Number(item.id))) {
            return { ...item, status: action.payload.status };
          } else {
            return item;
          }
        });
        state.payments.data = updatePayments;
      }
    },
    [updatePaymentStatus.rejected]: (state, action) => {
      state.updatePayment.isLoading = false;
      state.updatePayment.success = false;
      state.updatePayment.errMsg = action.payload;
    },
    /* handle delete payment  */
    [deletePayment.pending]: (state) => {
      state.delPayment.isLoading = true;
      state.delPayment.errMsg = null;
      state.delPayment.success = false;
    },
    [deletePayment.fulfilled]: (state, action) => {
      state.delPayment.success = true;
      state.delPayment.isLoading = false;
      state.delPayment.data = action.data;
      let prePayments = [...state.payments.data];
      let index = prePayments?.findIndex(
        (item) => item.id === action.payload.payment_id
      );
      prePayments.splice(index, 1);
      state.payments.data = prePayments;
      state.payments.totalPaymentAmount =
        Number(state.payments.totalPaymentAmount) -
        Number(action.payload.amount || 0);
      state.payments.pendingPaymentAmount =
        Number(state.payments.pendingPaymentAmount) -
        Number(action.payload.amount || 0);
    },
    [deletePayment.rejected]: (state, action) => {
      state.delPayment.isLoading = false;
      state.delPayment.success = false;
      state.delPayment.errMsg = action.payload;
    },
  },
});

export default Payments.reducer;
export * from "./getPayments";
export * from "./updatePaymentStatus";
