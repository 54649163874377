import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Button,
  Grid,
  TextField,
  InputLabel,
  Autocomplete as MuiAutocomplete,
  Card,
  Typography,
  Checkbox,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemButton,
  Switch,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styleSheet } from "./style.js";
import {
  returnKeyMultipleExistsInObject,
  decodeRangeValue,
  decodeMultipleValue,
  isChecked,
  decodeRangeValueWithoutFormat,
  decodeRangeValueWithFormat,
  generateValue,
  propertyRangListData,
} from "../../../utils/propertyData";
import {
  handleUpdateParams,
  handleResetSearchValue,
  getPropertyList,
  deleteSearch,
  updateSearchQuery,
} from "../../../redux/leads/savedSearches";
import locationIcon from "../../../assets/images/location.png";
import listingIcon from "../../../assets/images/listingIco.png";
import schoolIcon from "../../../assets/images/schoolIcon.png";
import buildingIcon from "../../../assets/images/buildingIcon.png";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ConfirmModal from "../../../components/widgets/modals/ConfirmModal.js";
import { updateSearch } from "../../../redux/leads/savedSearches/updateSaveSearch.js";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { isBoolean } from "lodash";

function SaveSearch(props) {
  const [selectedValue, setSelectedValue] = useState(null);
  let {
    classes,
    handleUpdateView,
    actionType,
    setErrorAlert,
    allowFiltersOnly,
    setOperator,
  } = props;
  const [emailNotification, setEmailNotification] = useState("daily");
  const [openAutocomplete, setOpenAutocomplete] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openAddFields, setOpenAddFields] = useState(false);
  const [focusedFields, setFocusedFields] = useState([]);
  const [isAND, setIsAND] = useState(false);
  const searchTitle = React.useRef(null);
  const [keysIndex, setKeysIndex] = useState({});
  const dispatch = useDispatch();
  const searchParams = useSelector(
    (item) => item.leads.savedSearches.searchParams
  );
  const suggestions = useSelector(
    (item) => item.leads.savedSearches.suggestions
  );
  const validFields = useSelector(
    (item) => item.leads.savedSearches.validFields
  );
  const geoOperator = useSelector(
    (item) => item.leads.savedSearches.geoOperator
  );
  const propertyList = useSelector(
    (item) => item.leads.savedSearches.propertyList
  );
  const saveSearch = useSelector((item) => item.leads.savedSearches.saveSearch);
  const searchDelete = useSelector(
    (item) => item.leads.savedSearches.deleteSearch
  );
  const searchList = useSelector((item) => item.leads.savedSearches.searchList);
  const searchDetail = useSelector(
    (item) => item.leads.savedSearches.searchDetail
  );

  const updateQuery = useSelector(
    (item) => item.leads.savedSearches.updateQuery
  );
  // Function to flatten the object
  const flattenObject = (obj, parentKey = "", isNested = false) => {
    const flattened = [];
    for (let key in obj) {
      if (obj[key].visible) {
        let newKey = key;
        if (
          typeof obj[key] === "object" &&
          !Array.isArray(obj[key]) &&
          obj[key] !== null &&
          !obj[key].type
        ) {
          if (newKey === "Additional Search Criteria") {
            const nested = flattenObject(obj[key], newKey, true);
            flattened.push({
              ...obj[key],
              key: newKey,
              nested,
            });
          } else {
            flattened.push(...flattenObject(obj[key], newKey));
          }
        } else {
          flattened.push({ ...obj[key], key: newKey });
        }
      }
    }
    return flattened;
  };

  const flattenObject1 = (obj) => {
    const flattened1 = [];
    for (let key in obj) {
      if (obj[key].visible) {
        let newKey = key;
        if (
          typeof obj[key] === "object" &&
          !Array.isArray(obj[key]) &&
          obj[key] !== null &&
          !obj[key].type
        ) {
          flattened1.push({ key: newKey, data: obj[key] });
        }
      }
    }
    return flattened1;
  };
  // Flatten and sort the object
  const flattenedData = flattenObject(validFields);
  const fieldsData = flattenedData.sort((a, b) => a.order - b.order);

  const handleReturnInnerOptions = (fieldsData) => {
    const newData = flattenObject(fieldsData);
    const interiorData = newData.sort((a, b) => a.order - b.order);
    return interiorData;
  };
  const handleReturnMainOptions = (fieldsData) => {
    const newData = flattenObject1(fieldsData);
    const interiorData = newData.sort((a, b) => a.order - b.order);
    return interiorData;
  };
  console.log(validFields, "validFields");
  const uniqueArray = (arr) => [...new Set(arr)];
  const handleUpdateSearchValue = (field, value, type, checked, rangeType) => {
    if (type === "multiple") {
      if (checked) {
        if (searchParams[field]) {
          if (
            field === "MlsStatus" &&
            value === "Active" &&
            validFields["statusesToIncludeAsActive"] &&
            validFields["statusesToIncludeAsActive"]?.options?.length
          ) {
            let newValue = `${searchParams[field]
              ?.replaceAll(")", "")
              ?.replaceAll(`"`, "")
              ?.replaceAll("(", "")} OR ${value}`;

            let newArray = uniqueArray([
              ...newValue?.split(" OR "),
              ...validFields["statusesToIncludeAsActive"]?.options,
            ]);
            dispatch(
              handleUpdateParams({
                field,
                value: decodeMultipleValue(newArray?.join(" OR ")),
              })
            );
          } else {
            let newValue = `${searchParams[field]
              ?.replaceAll(")", "")
              ?.replaceAll(`"`, "")
              ?.replaceAll("(", "")} OR ${value}`;
            dispatch(
              handleUpdateParams({
                field,
                value: decodeMultipleValue(newValue),
              })
            );
          }
        } else {
          if (
            field === "MlsStatus" &&
            value === "Active" &&
            validFields["statusesToIncludeAsActive"] &&
            validFields["statusesToIncludeAsActive"]?.options?.length
          ) {
          } else {
            dispatch(
              handleUpdateParams({
                field,
                value: value?.includes("OR")
                  ? `( ${value} )`
                  : value?.includes(" ")
                  ? `"${value}"`
                  : value,
              })
            );
          }
        }
      } else {
        let newValue = searchParams[field]
          ?.replaceAll(")", "")
          ?.replaceAll("(", "")
          ?.replaceAll(`"`, "")
          ?.split(" OR ")
          ?.filter((item) => item !== value)
          ?.join(" OR ");

        dispatch(
          handleUpdateParams({ field, value: decodeMultipleValue(newValue) })
        );
      }
    }
    if (type === "range") {
      let newValue;
      if (searchParams[field]) {
        if (rangeType === "min") {
          let maxValue = decodeRangeValue(searchParams[field], "max");
          if (maxValue === "*" && (value === "*" || !value)) {
            newValue = ``;
          } else {
            newValue = `[${value ? value : "*"} TO ${maxValue}]`;
          }
        } else {
          let minValue = decodeRangeValue(searchParams[field], "min");
          if (minValue === "*" && (value === "*" || !value)) {
            newValue = ``;
          } else {
            newValue = `[${minValue} TO ${value ? value : "*"}]`;
          }
        }
        dispatch(handleUpdateParams({ field, value: newValue }));
      } else {
        if (rangeType === "min") {
          newValue = value === "*" || !value ? "" : `[${value} TO *]`;
        } else {
          newValue = value === "*" || !value ? "" : `[* TO ${value}]`;
        }
        dispatch(handleUpdateParams({ field, value: newValue }));
      }
    }
    if (type === "boolean") {
      dispatch(handleUpdateParams({ field, value: checked }));
    }
    if (type === "number") {
      dispatch(handleUpdateParams({ field, value: value }));
    }
  };
  const filterOptions = (options, { inputValue }) => {
    if (inputValue?.length > 1) {
      if (inputValue) {
        const firstLetters = inputValue.trim().toUpperCase();
        return options
          .filter((option) =>
            option.title.toUpperCase().startsWith(firstLetters)
          )
          ?.slice(0, 500);
      } else {
        return options?.slice(0, 500);
      }
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (actionType === "update") {
      if (geoOperator && geoOperator === "AND") {
        setIsAND(true);
      } else if (geoOperator && geoOperator === "OR") {
        setIsAND(false);
      }
    }
  }, [geoOperator]);

  const handleReturnOptions = () => {
    let options = [];
    // Custom comparison function to sort based on the custom order
    const customSort = (a, b) => {
      const indexOfA = validFields?.[`LocationSearch`]?.fields
        ?.split(", ")
        .indexOf(a.group);
      const indexOfB = validFields?.[`LocationSearch`]?.fields
        ?.split(", ")
        .indexOf(b.group);
      if (indexOfA === -1) return 1; // Place unrecognized values at the end
      if (indexOfB === -1) return -1; // Place unrecognized values at the end
      return indexOfA - indexOfB;
    };
    let selectedOptions = handleReturnSelectedOption();
    validFields?.[`LocationSearch`]?.fields?.split(", ").map((item, index) => {
      suggestions[item]?.options?.map((it) => {
        if (
          !selectedOptions.find(
            (val) => val.value === it?.value[0] && val.key === item
          )
        ) {
          let obj = {
            title: it.friendlyName,
            group: item,
            value: it.value,
            groupTitle: suggestions[item]?.options,
          };
          options.push(obj);
        }
      });
    });
    return options.sort(customSort);
    // .sort((a, b) => {
    //   if (a.group < b.group) return -1;
    //   if (a.group > b.group) return 1;
    //   return a.title.localeCompare(b.title);
    // });
  };

  const renderGroup = (params) => (
    <Box>
      <Box className={classes.groupIconArea}>
        {" "}
        {params.group === "City" || params.group === "CountyOrParish" ? (
          <img src={locationIcon} alt="locationIcon" width={26} height={26} />
        ) : null}
        {params.group === "SubdivisionName" ? (
          <img src={buildingIcon} alt="buildingIcon" width={26} height={26} />
        ) : null}
        {params.group === "PostalCode" ? (
          <img src={listingIcon} alt="listingIcon" width={26} height={26} />
        ) : null}
        {params.group === "ElementarySchool" ||
        params.group === "HighSchool" ||
        params.group === "MiddleSchool" ||
        params.group === "MiddleOrJuniorSchool" ? (
          <img src={schoolIcon} alt="locationIcon" width={26} height={26} />
        ) : null}
        {suggestions[params.group]?.friendlyTitle}
      </Box>
      <Box className={classes.groupItem}>{params.children}</Box>
    </Box>
  );

  const handleReturnSelectedOption = () => {
    let newFilteredOptions = [];
    let newKeys = returnKeyMultipleExistsInObject(
      searchParams,
      validFields?.[`LocationSearch`]?.fields?.split(", ")
    );
    for (let i = 0; i < newKeys.length; i++) {
      let fondKey = newKeys[i];
      let fountKeyValue = searchParams[fondKey];
      if (fountKeyValue) {
        if (fountKeyValue?.includes("(") || fountKeyValue?.includes(")")) {
          let newSplitedValue = fountKeyValue
            ?.replaceAll("(", "")
            ?.replaceAll(")", "")
            ?.split(" OR ");
          for (let j = 0; j < newSplitedValue.length; j++) {
            newFilteredOptions.push({
              key: fondKey,
              value: newSplitedValue[j]?.replaceAll(`"`, ""),
            });
          }
        } else {
          newFilteredOptions.push({
            key: fondKey,
            value: fountKeyValue?.replaceAll(`"`, ""),
          });
        }
      }
    }
    return newFilteredOptions;
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "Your have successfully saved the search!",
      errorType: "success",
      isOpen: true,
    });
    dispatch(handleResetSearchValue());
    setTimeout(() => {
      handleUpdateView("search-list", "");
    }, 1500);
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSubmit = () => {
    if (searchTitle?.current?.value) {
      let isCityStateZipCode = false;
      let newKeys = [];
      Object.keys(searchParams).forEach((k) => {
        if (searchParams[k]) {
          if (
            !isCityStateZipCode &&
            validFields?.[`LocationSearch`]?.fields?.split(", ")?.includes(k)
          ) {
            isCityStateZipCode = true;
          }
          newKeys.push(k);
        }
      });
      if (newKeys?.length > 1) {
        if (isCityStateZipCode) {
          dispatch(
            getPropertyList({
              title: searchTitle?.current?.value,
              emailNotification: emailNotification,
              handleSuccess,
              handleError,
              isAND: isAND,
            })
          );
        } else {
          setErrorAlert({
            errorMsg:
              "Please select at least one from Cities, Zip Codes, Schools or Subdivision",
            errorType: "warning",
            isOpen: true,
          });
        }
      } else {
        setErrorAlert({
          errorMsg: "Please set the search criteria first",
          errorType: "warning",
          isOpen: true,
        });
      }
    } else {
      setErrorAlert({
        errorMsg: "Please enter the search Title",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleSuccessUpdate = () => {
    dispatch(
      updateSearch({
        title: searchTitle?.current?.value,
        emailNotification: emailNotification,
        searchId: searchDetail?.searchId,
      })
    );

    setErrorAlert({
      errorMsg: "Your have successfully updated the search",
      errorType: "success",
      isOpen: true,
    });
    dispatch(handleResetSearchValue());
    setTimeout(() => {
      handleUpdateView("search-list", "");
    }, 1500);
  };

  const handleUpdateSearch = () => {
    let isCityStateZipCode = false;
    Object.keys(searchParams).forEach((k) => {
      if (searchParams[k]) {
        if (
          !isCityStateZipCode &&
          validFields?.[`LocationSearch`]?.fields?.split(", ")?.includes(k)
        ) {
          isCityStateZipCode = true;
        }
      }
    });
    if (isCityStateZipCode || searchDetail?.advanceSearchId?.query?.fq) {
      dispatch(
        updateSearchQuery({
          searchId: searchDetail?.searchId,
          handleError,
          handleSuccess: handleSuccessUpdate,
          title: searchTitle?.current?.value,
          isAND: isAND,
          agentId: searchList?.data?.assignedAgent?._id,
          fq: searchDetail?.advanceSearchId?.query?.fq,
        })
      );
    } else {
      setErrorAlert({
        errorMsg: "Please select at least one from Cities, Zip Codes, Schools",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleSuccessDelete = () => {
    setErrorAlert({
      errorMsg: "Your have successfully deleted the search",
      errorType: "success",
      isOpen: true,
    });
    dispatch(handleResetSearchValue());
    setTimeout(() => {
      handleUpdateView("search-list", "");
    }, 1500);
    setOpenDelete(false);
  };

  const handleConfirm = () => {
    dispatch(deleteSearch({ handleError, handleSuccess: handleSuccessDelete }));
  };
  useEffect(() => {
    if (
      actionType === "update" &&
      searchDetail?.searchId &&
      searchTitle.current
    ) {
      searchTitle.current.value = searchDetail.title;
      setEmailNotification(searchDetail.emailNotification);
    }
  }, [actionType, searchDetail?.searchId]);

  const handleUpdateFocusFields = (field, type) => {
    let newFocusedFields = [...focusedFields];
    if (type === "add") {
      newFocusedFields.push(field);
      setFocusedFields(newFocusedFields);
    } else {
      setFocusedFields(newFocusedFields.filter((item) => item !== `${field}`));
    }
  };

  const handleReturnSubPropertyType = (options, value) => {
    let subTypesOption = [];

    options?.map((option, index) => {
      if (value?.includes(option.value)) {
        subTypesOption = [...subTypesOption, ...option.subTypes];
      }
    });
    return subTypesOption?.filter(
      (opt, index, self) =>
        self.findIndex((item) => item.value === opt.value) == index
    );
  };

  const handleScrollEvent = (e, key) => {
    //console.log(e);
    var bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5;
    if (bottom) {
      setKeysIndex((preIndex) => ({
        ...preIndex,
        [key]: (preIndex[key] || 1) + 1,
      }));
    }
  };

  return (
    <Box className={allowFiltersOnly ? "" : classes.saveSearchArea}>
      <Grid container spacing={3}>
        {fieldsData?.map((field, index) => {
          switch (field?.type) {
            case "input":
              return allowFiltersOnly ? null : (
                <Grid item xs={12}>
                  <InputLabel className={classes.fieldLabel}>
                    {field?.label}
                  </InputLabel>
                  {field.key === "Name" ? (
                    <TextField
                      placeholder="Enter Search Name"
                      variant="standard"
                      fullWidth
                      size="small"
                      inputRef={searchTitle}
                      onChange={(e) => {
                        if (e.target.value) {
                          searchTitle.current.value = e.target.value;
                        }
                      }}
                    />
                  ) : (
                    <TextField
                      placeholder={`Enter ${field?.label}`}
                      variant="standard"
                      fullWidth
                      size="small"
                      value={searchParams?.fieldKey}
                      onChange={(e) => {
                        dispatch(
                          handleUpdateParams({
                            field: field.key,
                            value: e.target.value,
                          })
                        );
                      }}
                    />
                  )}
                </Grid>
              );
            case "select":
              return allowFiltersOnly ? null : (
                <Grid item xs={12}>
                  <InputLabel className={classes.fieldLabel}>
                    {field?.label}
                  </InputLabel>
                  {field.key === "Frequency" ? (
                    <TextField
                      value={emailNotification}
                      onChange={(e) => {
                        setEmailNotification(e.target.value);
                      }}
                      fullWidth
                      variant="standard"
                      size="small"
                      select
                    >
                      <MenuItem value={"daily"}>Daily</MenuItem>
                      <MenuItem value={"weekly"}>Weekly </MenuItem>
                      <MenuItem value={"monthly"}>Monthly </MenuItem>
                      <MenuItem value={"never"}>Never </MenuItem>
                    </TextField>
                  ) : (
                    <TextField
                      value={
                        field.key === "SortBy"
                          ? searchParams?.sort
                          : searchParams?.[field.key]
                      }
                      onChange={(e) => {
                        dispatch(
                          handleUpdateParams({
                            field: field.key === "SortBy" ? "sort" : field.key,
                            value: e.target.value,
                          })
                        );
                      }}
                      fullWidth
                      variant="standard"
                      size="small"
                      select
                    >
                      {field.options?.map((options) => (
                        <MenuItem value={options.value}>
                          {options?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
              );
            case "range":
              return (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {" "}
                    <Grid item xs={6}>
                      <InputLabel className={classes.fieldLabel}>
                        Minimum {field?.label}
                      </InputLabel>
                      {!propertyRangListData[field.key] ? (
                        <TextField
                          fullWidth
                          size="small"
                          variant="standard"
                          type={
                            focusedFields?.includes(`min${field.key}`)
                              ? "number"
                              : "text"
                          }
                          value={
                            searchParams?.[field.key]
                              ? focusedFields?.includes(`min${field.key}`)
                                ? decodeRangeValueWithoutFormat(
                                    searchParams?.[field.key],
                                    "min"
                                  )
                                : decodeRangeValueWithFormat(
                                    searchParams?.[field.key],
                                    "min",
                                    "standard"
                                  )
                              : null
                          }
                          onFocus={() =>
                            handleUpdateFocusFields(`min${field.key}`, "add")
                          }
                          onBlur={() =>
                            handleUpdateFocusFields(`min${field.key}`, "remove")
                          }
                          onChange={(e) =>
                            handleUpdateSearchValue(
                              field.key,
                              e.target?.value
                                ?.replace("$", "")
                                ?.replaceAll("*", ""),
                              "range",
                              false,
                              "min"
                            )
                          }
                        />
                      ) : (
                        <MuiAutocomplete
                          options={
                            Number(
                              decodeRangeValue(
                                searchParams?.[field.key],
                                "max"
                              ) || 0
                            ) > 0
                              ? propertyRangListData[field.key]?.filter(
                                  (item) =>
                                    Number(item.value) <
                                      Number(
                                        decodeRangeValue(
                                          searchParams?.[field.key],
                                          "max"
                                        )
                                      ) || item.value === "*"
                                )
                              : propertyRangListData[field.key]
                          }
                          value={
                            searchParams?.[field.key]
                              ? propertyRangListData[field.key]?.find(
                                  (item) =>
                                    item.value ===
                                    decodeRangeValue(
                                      searchParams?.[field.key],
                                      "min"
                                    )
                                )
                              : null
                          }
                          getOptionLabel={(option) => option.title}
                          onChange={(event, newValue) => {
                            handleUpdateSearchValue(
                              field.key,
                              newValue?.value,
                              "range",
                              false,
                              "min"
                            );
                            setOpenAutocomplete(`${field.key}MaxRang`);
                            document
                              .getElementById(`${field.key}MaxRang`)
                              ?.click();
                          }}
                          fullWidth
                          freeSolo
                          id={`${field.key}MinRang`}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="standard"
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel className={classes.fieldLabel}>
                        Maximum {field?.label}
                      </InputLabel>
                      {!propertyRangListData[field.key] ? (
                        <TextField
                          fullWidth
                          size="small"
                          variant="standard"
                          type={
                            focusedFields?.includes(`max${field.key}`)
                              ? "number"
                              : "text"
                          }
                          value={
                            searchParams?.[field.key]
                              ? focusedFields?.includes(`max${field.key}`)
                                ? decodeRangeValueWithoutFormat(
                                    searchParams?.[field.key],
                                    "max"
                                  )
                                : decodeRangeValueWithFormat(
                                    searchParams?.[field.key],
                                    "max",
                                    "standard"
                                  )
                              : null
                          }
                          onFocus={() =>
                            handleUpdateFocusFields(`max${field.key}`, "add")
                          }
                          onBlur={() =>
                            handleUpdateFocusFields(`max${field.key}`, "remove")
                          }
                          onChange={(e) =>
                            handleUpdateSearchValue(
                              field.key,
                              e.target?.value
                                ?.replace("$")
                                ?.replaceAll("*", ""),
                              "range",
                              false,
                              "max"
                            )
                          }
                        />
                      ) : (
                        <MuiAutocomplete
                          options={
                            decodeRangeValue(
                              searchParams?.[field.key],
                              "min"
                            ) === "*" ||
                            !decodeRangeValue(searchParams?.[field.key], "min")
                              ? propertyRangListData[field.key]
                              : propertyRangListData[field.key]?.filter(
                                  (item) =>
                                    Number(item?.value) >
                                      Number(
                                        decodeRangeValue(
                                          searchParams?.[field.key],
                                          "min"
                                        ) || 0
                                      ) || item.value === "*"
                                )
                          }
                          value={
                            searchParams?.[field.key]
                              ? propertyRangListData[field.key]?.find(
                                  (item) =>
                                    item.value ===
                                    decodeRangeValue(
                                      searchParams?.[field.key],
                                      "max"
                                    )
                                )
                              : null
                          }
                          fullWidth
                          freeSolo
                          id={`${field.key}MaxRang`}
                          getOptionLabel={(option) =>
                            option.title?.replace("+", "")
                          }
                          open={
                            openAutocomplete === `${field.key}MaxRang`
                              ? true
                              : false
                          } // Control the open state of the dropdown
                          onOpen={() =>
                            setOpenAutocomplete(`${field.key}MaxRang`)
                          } // Handle when the dropdown opens
                          onClose={() => setOpenAutocomplete("")} // Handle when the dropdown closes
                          onChange={(event, newValue) => {
                            handleUpdateSearchValue(
                              field.key,
                              newValue?.value,
                              "range",
                              false,
                              "max"
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="standard"
                            />
                          )}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              );
            case "search":
              return (
                <Grid item xs={12}>
                  <InputLabel className={classes.fieldLabel}>
                    {fieldsData[field.key]?.label}
                  </InputLabel>
                  <Box className={classes.switchSection}>
                    {" "}
                    <Box className={classes.switchSectionInner}>
                      <InputLabel className={classes.fieldLabel}>OR</InputLabel>
                      <Switch
                        checked={isAND}
                        onChange={(e) => {
                          setIsAND(e.target.checked);
                          if (allowFiltersOnly) {
                            setOperator(allowFiltersOnly);
                          }
                        }}
                        sx={{ mt: "-8px" }}
                        size="small"
                      />
                      <InputLabel className={classes.fieldLabel}>
                        AND
                      </InputLabel>
                    </Box>
                  </Box>

                  <MuiAutocomplete
                    options={handleReturnOptions()}
                    filterOptions={filterOptions}
                    fullWidth
                    freeSolo
                    size="small"
                    renderGroup={renderGroup}
                    groupBy={(option) => option.group}
                    getOptionLabel={(option) => option.title}
                    value={selectedValue}
                    clearOnBlur
                    clearOnEscape
                    onChange={(event, newValue) => {
                      setSelectedValue(newValue);
                      if (
                        searchParams[newValue?.group] &&
                        searchParams[newValue?.group]?.length
                      ) {
                        handleUpdateSearchValue(
                          newValue?.group,
                          generateValue(newValue, true),
                          "multiple",
                          true
                        );
                      } else {
                        dispatch(
                          handleUpdateParams({
                            field: newValue?.group,
                            value: generateValue(newValue, true)?.includes("OR")
                              ? `(${generateValue(newValue, true)})`
                              : generateValue(newValue, true),
                          })
                        );
                      }
                      setTimeout(() => {
                        setSelectedValue(null);
                      }, 200);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Hide the border
                        },
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Start Typing"
                        size="small"
                        variant="standard"
                      />
                    )}
                  />

                  <Box className={classes.selectedList}>
                    {handleReturnSelectedOption()?.map((item) => (
                      <Chip
                        label={item.value}
                        onDelete={() => {
                          handleUpdateSearchValue(
                            item.key,
                            `${item.value?.replaceAll(`"`, "")}`,
                            "multiple",
                            false
                          );
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
              );
            case "checkbox":
              return (
                <Grid item xs={12} sx={{ mb: "10px" }}>
                  <InputLabel className={classes.fieldLabel}>
                    {field?.label}
                  </InputLabel>
                  <Box className={classes.propertyTypeAreaItems}>
                    {field.key === "PropertySubType" &&
                    suggestions["PropertyType"]?.options?.[0]?.subTypes
                      ? handleReturnSubPropertyType(
                          suggestions["PropertyType"]?.options,
                          searchParams?.["PropertyType"]
                        )?.map((option) => (
                          <Card
                            key={option.friendlyName}
                            variant="outlined"
                            className={
                              isChecked(
                                searchParams?.[field?.key],
                                option.value
                              )
                                ? classes.propertyTypeAreaItemActive
                                : classes.propertyTypeAreaItem
                            }
                          >
                            <Typography
                              className={classes.propertyTypeAreaItemDes}
                            >
                              {option.friendlyName}
                            </Typography>
                            <Checkbox
                              checked={isChecked(
                                searchParams?.[field?.key],
                                option.value
                              )}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: "18px" },
                                color: "rgba(0, 0, 0, 0.54)",
                                "&.Mui-checked": {
                                  color: "rgba(0, 0, 0, 0.54)",
                                },
                              }}
                              kay={option.value}
                              onChange={(event) => {
                                handleUpdateSearchValue(
                                  field?.key,
                                  option.value,
                                  "multiple",
                                  event.target.checked
                                );
                                if (option.value === "Residential Income") {
                                  setTimeout(() => {
                                    dispatch(
                                      handleUpdateParams({
                                        field: "PropertySubType",
                                        value: "",
                                      })
                                    );
                                  }, 500);
                                }
                              }}
                            />
                          </Card>
                        ))
                      : suggestions[field.key]?.options?.map((option) => (
                          <Card
                            key={option.friendlyName}
                            variant="outlined"
                            className={
                              isChecked(
                                searchParams?.[field?.key],
                                option.value?.[0]
                              )
                                ? classes.propertyTypeAreaItemActive
                                : classes.propertyTypeAreaItem
                            }
                          >
                            <Typography
                              className={classes.propertyTypeAreaItemDes}
                            >
                              {option.friendlyName}
                            </Typography>
                            <Checkbox
                              checked={isChecked(
                                searchParams?.[field?.key],
                                option.value?.[0]
                              )}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: "18px" },
                                color: "rgba(0, 0, 0, 0.54)",
                                "&.Mui-checked": {
                                  color: "rgba(0, 0, 0, 0.54)",
                                },
                              }}
                              kay={option.value?.[0]}
                              onChange={(event) => {
                                handleUpdateSearchValue(
                                  field?.key,
                                  option.value?.[0],
                                  "multiple",
                                  event.target.checked
                                );
                                if (
                                  option.value?.[0] === "Residential Income"
                                ) {
                                  setTimeout(() => {
                                    dispatch(
                                      handleUpdateParams({
                                        field: "PropertySubType",
                                        value: "",
                                      })
                                    );
                                  }, 500);
                                }
                              }}
                            />
                          </Card>
                        ))}
                  </Box>
                </Grid>
              );
            case "boolean":
              return (
                <Grid item xs={12}>
                  <Card
                    variant="outlined"
                    className={
                      searchParams?.[field.key]
                        ? classes.propertyTypeAreaItemActive
                        : classes.propertyTypeAreaItem
                    }
                    sx={{ width: "fit-content" }}
                  >
                    <Typography className={classes.propertyTypeAreaItemDes}>
                      {field?.label}
                    </Typography>
                    <Checkbox
                      checked={
                        field.key === "AssociationYN" &&
                        typeof searchParams?.["AssociationYN"] === "boolean"
                          ? !searchParams?.[field.key]
                          : searchParams?.[field.key]
                      }
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: "18px" },
                        color: "rgba(0, 0, 0, 0.54)",
                        "&.Mui-checked": {
                          color: "rgba(0, 0, 0, 0.54)",
                        },
                      }}
                      key={field.key}
                      onChange={(event) => {
                        handleUpdateSearchValue(
                          field.key,
                          "",
                          "boolean",
                          field.key === "AssociationYN"
                            ? !Boolean(event?.target?.checked)
                            : event?.target?.checked
                        );
                      }}
                    />
                  </Card>
                </Grid>
              );
            case "additionalSearch":
              return (
                <Grid item xs={12}>
                  <Button
                    variant="text"
                    className={classes.additionalFieldsButton}
                    endIcon={
                      openAddFields ? (
                        <KeyboardArrowUpRoundedIcon />
                      ) : (
                        <KeyboardArrowDownRoundedIcon />
                      )
                    }
                    onClick={() => setOpenAddFields((op) => !op)}
                  >
                    {field?.key}
                  </Button>
                  <Box
                    className={classes.additionFieldsArea}
                    sx={{
                      height: openAddFields ? "auto" : "0px",
                      visibility: openAddFields ? "visible" : "hidden",
                    }}
                  >
                    {handleReturnMainOptions(field)?.map((item, index) => (
                      <Box key={index}>
                        <Typography
                          sx={{ mb: "15px" }}
                          className={classes.additionFieldsHeading}
                        >
                          {item?.key}
                        </Typography>

                        {handleReturnInnerOptions(item?.data)?.map(
                          (fieldItem) => {
                            switch (fieldItem?.type) {
                              case "input":
                                return (
                                  <Box sx={{ mt: "30px" }}>
                                    <InputLabel className={classes.fieldLabel}>
                                      {fieldItem?.label}
                                    </InputLabel>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      variant="standard"
                                      type={
                                        focusedFields?.includes(fieldItem?.key)
                                          ? "number"
                                          : "text"
                                      }
                                      value={searchParams?.[fieldItem?.key]}
                                      onFocus={() =>
                                        handleUpdateFocusFields(
                                          fieldItem?.key,
                                          "add"
                                        )
                                      }
                                      onBlur={() =>
                                        handleUpdateFocusFields(
                                          fieldItem?.key,
                                          "remove"
                                        )
                                      }
                                      onChange={(e) =>
                                        handleUpdateSearchValue(
                                          fieldItem?.key,
                                          e.target?.value,
                                          "number",
                                          false,
                                          ""
                                        )
                                      }
                                    />
                                  </Box>
                                );
                              case "boolean":
                                return (
                                  <Card
                                    variant="outlined"
                                    className={
                                      searchParams?.MethodMasterOnMain
                                        ? classes.propertyTypeAreaItemActive
                                        : classes.propertyTypeAreaItem
                                    }
                                    sx={{ width: "fit-content" }}
                                  >
                                    <Typography
                                      className={
                                        classes.propertyTypeAreaItemDes
                                      }
                                    >
                                      {fieldItem?.label}
                                    </Typography>
                                    <Checkbox
                                      checked={searchParams?.[fieldItem?.key]}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "18px",
                                        },
                                        color: "rgba(0, 0, 0, 0.54)",
                                        "&.Mui-checked": {
                                          color: "rgba(0, 0, 0, 0.54)",
                                        },
                                      }}
                                      key={fieldItem?.key}
                                      onChange={(event) => {
                                        handleUpdateSearchValue(
                                          fieldItem?.key,
                                          "",
                                          "boolean",
                                          event?.target?.checked
                                        );
                                      }}
                                    />
                                  </Card>
                                );
                              case "select":
                                return (
                                  <Box className={classes.additionalFieldsItem}>
                                    <InputLabel className={classes.fieldLabel}>
                                      {fieldItem?.label}
                                    </InputLabel>
                                    <Card
                                      variant="outlined"
                                      className={classes.additionalFieldsCard}
                                    >
                                      <List
                                        disablePadding
                                        className={classes.additionalFieldsList}
                                        onScroll={(e) => {
                                          if (
                                            suggestions[fieldItem?.key]?.options
                                              ?.length >
                                            (keysIndex[fieldItem?.key] || 1) *
                                              20
                                          ) {
                                            handleScrollEvent(
                                              e,
                                              fieldItem?.key
                                            );
                                          }
                                        }}
                                      >
                                        {suggestions[fieldItem?.key]?.options
                                          ?.slice(
                                            0,
                                            (keysIndex[fieldItem?.key] || 1) *
                                              20
                                          )
                                          ?.map((item, index) => (
                                            <ListItem
                                              key={index}
                                              className={
                                                isChecked(
                                                  searchParams[fieldItem?.key],
                                                  generateValue(item, false)
                                                )
                                                  ? classes.selectedItem
                                                  : ""
                                              }
                                              disablePadding
                                              onClick={(event) => {
                                                handleUpdateSearchValue(
                                                  fieldItem?.key,
                                                  generateValue(item, false),
                                                  "multiple",
                                                  isChecked(
                                                    searchParams[
                                                      fieldItem?.key
                                                    ],
                                                    generateValue(item, false)
                                                  )
                                                    ? false
                                                    : true
                                                );
                                              }}
                                            >
                                              <ListItemButton dense>
                                                <ListItemText
                                                  primary={item.friendlyName}
                                                />
                                                {isChecked(
                                                  searchParams[fieldItem?.key],
                                                  generateValue(item)
                                                ) ? (
                                                  <ListItemSecondaryAction>
                                                    <CheckRoundedIcon
                                                      sx={{
                                                        color:
                                                          "rgba(12, 150, 0, 0.54)",
                                                        fontSize: "18px",
                                                      }}
                                                    />
                                                  </ListItemSecondaryAction>
                                                ) : null}
                                              </ListItemButton>
                                            </ListItem>
                                          ))}
                                      </List>
                                    </Card>
                                  </Box>
                                );
                              default:
                                return null;
                            }
                          }
                        )}

                        <br />
                        <br />
                      </Box>
                    ))}
                  </Box>
                </Grid>
              );
            default:
              return null;
          }
        })}
      </Grid>
      {allowFiltersOnly ? null : (
        <Box className={classes.saveButtonArea}>
          <Button
            onClick={() => {
              handleUpdateView("search-list", "");
              dispatch(handleResetSearchValue());
            }}
            color="inherit"
            variant="outlined"
            sx={{ fontSize: "13px" }}
          >
            Cancel
          </Button>
          {actionType === "update" ? (
            <Button
              className={classes.searchDeleteButton}
              sx={{ paddingLeft: "15px", paddingRight: "15px" }}
              onClick={() => setOpenDelete(true)}
            >
              Delete
            </Button>
          ) : null}
          {actionType === "update" ? (
            <LoadingButton
              className={classes.searchSaveButton}
              onClick={handleUpdateSearch}
              loading={Boolean(updateQuery.isLoading)}
              loadingPosition="start"
            >
              Update Search
            </LoadingButton>
          ) : (
            <LoadingButton
              className={classes.searchSaveButton}
              onClick={() => handleSubmit()}
              loading={Boolean(propertyList.isLoading || saveSearch?.isLoading)}
              loadingPosition="start"
              fullWidth
            >
              Save Search
            </LoadingButton>
          )}
        </Box>
      )}

      <ConfirmModal
        title={`Are you sure to delete search "${searchTitle?.current?.value}".`}
        open={openDelete}
        setOpen={setOpenDelete}
        handleConfirm={handleConfirm}
        loading={searchDelete.isLoading}
      />
    </Box>
  );
}
export default withStyles(styleSheet, { name: "SaveSearchStyle" })(SaveSearch);
