import React, { useState, useEffect } from "react";
import { Box, Tabs, Tab, Card, Button, useMediaQuery } from "@mui/material";
import { TabContext } from "@mui/lab";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import { Link, useLocation } from "react-router-dom";
import ButtonGroups from "../../../components/uiElements/buttonGroup";
import { styled, alpha } from "@mui/material/styles";
import { Container } from "@mui/system";
import TransactionsActiveContracts from "./contract";
import { Route, Routes } from "react-router-dom";
import { getAllTransactions } from "../../../redux/transactions";
import { useDispatch } from "react-redux";
import ContractsTasksTab from "./tasks";
import NeedApprovalContracts from "./needApproval";
import TabMenu from "./TabMenu";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "36px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  marginLeft: 0,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function TransactionsList(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  let { classes, id = 1 } = props;

  const makeDropDown = useMediaQuery("(max-width:1024px)");
  const [search, setSearch] = useState(false);
  const [filters, setFilters] = useState({
    pageNumber: 1,
    pageSize: 30,
    sortBy: "",
    status:
      location.pathname === "/transaction/contracts/active-contracts"
        ? "active"
        : location.pathname === "/transaction/contracts/closed"
        ? "closed"
        : location.pathname === "/transaction/contracts/terminated"
        ? "terminated"
        : "all",
    filterByType: "",
    search: "",
  });

  useEffect(() => {
    dispatch(
      getAllTransactions({
        ...filters,
        reset: filters.pageNumber === 1 ? true : false,
      })
    );
  }, [filters]);

  const handleUpdateFilter = (field, value, field1, value1) => {
    if (field1) {
      setFilters({
        ...filters,
        pageNumber: 1,
        [field]: value,
        [field1]: value1,
      });
    } else {
      if (field === "filterByType") {
        setFilters({
          ...filters,
          pageNumber: 1,
          [field]: value,
        });
      } else {
        setFilters({
          ...filters,
          pageNumber: 1,
          [field]: value,
          filterByType: "",
        });
      }
    }
  };

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(
        () => setFilters({ ...filters, search }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          position: "sticky",
          top: "64px",
          zIndex: "100",
          overflow: "visible",
        }}
      >
        {" "}
        <Box className={classes.TopTransactionsBarArea}>
          <Box className={classes.TopTransactionsBarAreaLeft}>
            {" "}
            <Box>
              {" "}
              <TabContext>
                <Box className={classes.TransactionsTabsUI}>
                  {!makeDropDown && (
                    <Tabs
                      value={location.pathname}
                      TabIndicatorProps={{
                        sx: { background: "black", color: "black" },
                      }}
                      variant="scrollable"
                      aria-label="transactions-tabs"
                      sx={{ justifyContent: "space-around" }}
                    >
                      <Tab
                        label="Active Contracts"
                        value={`/transaction/contracts/active-contracts`}
                        to={`/transaction/contracts/active-contracts`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                        onClick={() => handleUpdateFilter("status", "active")}
                      />
                      <Tab
                        label="Referral Contracts"
                        value={`/transaction/contracts/referral-contracts`}
                        to={`/transaction/contracts/referral-contracts`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                        onClick={() =>
                          handleUpdateFilter(
                            "status",
                            "active",
                            "filterByType",
                            "referral"
                          )
                        }
                      />
                      <Tab
                        label="Terminated"
                        value={`/transaction/contracts/terminated`}
                        to={`/transaction/contracts/terminated`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                        onClick={() =>
                          handleUpdateFilter("status", "terminated")
                        }
                      />{" "}
                      <Tab
                        label="CLOSED"
                        value={`/transaction/contracts/closed`}
                        to={`/transaction/contracts/closed`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                        onClick={() => handleUpdateFilter("status", "closed")}
                      />
                      <Tab
                        label="Need Approval"
                        value={`/transaction/contracts/need-approval`}
                        to={`/transaction/contracts/need-approval`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                        onClick={() =>
                          handleUpdateFilter("status", "terminated")
                        }
                      />{" "}
                      <Tab
                        label="Tasks"
                        value={`/transaction/contracts/tasks`}
                        to={`/transaction/contracts/tasks`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                        onClick={() => handleUpdateFilter("status", "closed")}
                      />
                    </Tabs>
                  )}
                  {makeDropDown && (
                    <Tabs
                      value={location.pathname}
                      TabIndicatorProps={{
                        sx: { background: "black", color: "black" },
                      }}
                      variant="scrollable"
                      aria-label="transactions-tabs"
                      sx={{ justifyContent: "space-around" }}
                    >
                      <TabMenu handleChange={handleUpdateFilter} />
                    </Tabs>
                  )}
                </Box>
              </TabContext>{" "}
            </Box>{" "}
            <Box sx={{ marginLeft: "20px" }}>
              {" "}
              <ButtonGroups
                id="category-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                color="white"
                fontSize="12px"
                minWidth="160px"
                options={[
                  { title: "All Rental Contracts", value: "all_rentals" },
                  { title: "All Sales Contracts", value: "all_sales" },
                  {
                    title: "Listing Contracts",
                    value: "seller",
                  } /* seller or listing. */,
                  { title: "Buyer Contracts", value: "buyer" },
                  { title: "Tenant Contracts", value: "landlord" },
                  { title: "Landlord Contracts", value: "tenant" },
                  { title: "Referral Contracts", value: "referral" },
                ]}
                onChangeMenu={(value) =>
                  handleUpdateFilter("filterByType", value)
                }
                placeholder="Filter by Type"
                {...props}
              />{" "}
            </Box>{" "}
          </Box>
          <Box className={classes.TopTransactionsBarAreaLeft}>
            {" "}
            <Button
              to={`/transaction/contracts/add-contract/`}
              component={Link}
              className={classes.addTransactionButton}
              variant="contained"
              startIcon={<CreateNewFolderRoundedIcon />}
            >
              New Contract
            </Button>{" "}
            <Search>
              <StyledInputBase
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Box>
        </Box>
      </Card>
      <Container className={classes.transactionsStatsArea} maxWidth="lg">
        <Routes>
          <Route
            path="/contracts"
            element={
              <TransactionsActiveContracts
                setFilters={setFilters}
                filters={filters}
                {...props}
              />
            }
          />
          <Route
            path="/contracts/active-contracts"
            element={
              <TransactionsActiveContracts
                setFilters={setFilters}
                filters={filters}
                {...props}
              />
            }
          />
          <Route
            path="/contracts/referral-contracts"
            element={
              <TransactionsActiveContracts
                setFilters={setFilters}
                filters={filters}
                {...props}
              />
            }
          />
          <Route
            path="/contracts/terminated"
            element={
              <TransactionsActiveContracts
                setFilters={setFilters}
                filters={filters}
                {...props}
              />
            }
          />
          <Route
            path="/contracts/closed"
            element={
              <TransactionsActiveContracts
                setFilters={setFilters}
                filters={filters}
                {...props}
              />
            }
          />
          <Route
            path="/contracts/need-approval"
            element={<NeedApprovalContracts {...props} />}
          />
          <Route
            path="/contracts/tasks"
            element={<ContractsTasksTab {...props} />}
          />
        </Routes>
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "TransactionsListStyle" })(
  TransactionsList
);
