export const styleSheet = () => ({
  TopTransactionsBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopTransactionsBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  "@media (max-width: 1270px)": {
    TransactionsTabsUI: {
      maxWidth: "420px",
    },
  },
  "@media (max-width: 1050px)": {
    TransactionsTabsUI: {
      maxWidth: "300px",
    },
  },
  TransactionsTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
  tableRoot: {
    height: "calc(100vh - 120px)",
    width: "100%",
    backgroundColor: "white",
    position: "relative",
    maxHeight: "900px",
    minHeight: "500px",
  },
  makeoverListArea: {
    marginTop: "30px",
    minHeight: "",
  },
  makeoverListAreaTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  makeoverListHeading: {
    fontSize: "14px !important",
  },
  tableRootFooterItem1: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  makeoverTopStat: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "230px",
    backgroundColor: "#e7e7e7 !important",
    borderRadius: "6px  !important",
    padding: "5.5px 12px  !important",
  },
});
